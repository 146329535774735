

export const AccountSelect = ({isAccountList, onSelectChange, selectedAcctTitle, accountList}) => { 

    const handleChange = (event) => {
        onSelectChange(event)
    }

    if(isAccountList){
        return (
            <>
                <div className='col-sm-2 account-label-container'>
                    <label className='account-label'>Account:</label>
                </div>
                <div className='col-sm-5'>
                    <select value={selectedAcctTitle} onChange={handleChange} className="custom-select account-select" id="account" name="account">
                        {accountList.map((account) => (
                            
                            <option value={account.title}>{account.title}</option>
                                                    
                        ))}
                    </select>
                </div>               
            </>
        )
    } 
}