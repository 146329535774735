import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import { useEffect, useState } from 'react'
import {getAutocomplete} from '../utils.js'

import { Loader } from './loader.js';



export const Form = ({ onSubmitForm, selectedAcctTitle, selectedAccount }) => {

    let today = new Date()
    today = today.toISOString().split('T')[0]

    const [countryCodes, setcountryCodes] = useState(["US", "CA"])

    const [isSameDay, setIsSameDay] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [formData, setFormData] = useState(
    {eqType: "VAN",shipDate: today,pickupCity: "",pickupState: "PICKUP",pickupCountry: "US",pickupZip: "",dropoffCity: "",dropoffState: "DROPOFF",dropoffCountry: "US",dropoffZip: ""});

    const [isPickupAutoCoord, setIsPickupAutoCoord] = useState(false);
    const [isDropoffAutoCoord, setIsDropoffAutoCoord] = useState(false);
    
    const [coords, setCoords] = useState({})

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          pickupDateTime: formData.shipDate.concat("T00:00:00Z"),
          transportType: formData.eqType == "HOTSHOT" ? "FLATBED" : formData.eqType,
          stops: [
            {order: 0, country: formData.pickupCountry, city: formData.pickupCity, state: formData.pickupState, zip: formData.pickupZip},
            {order: 1, country: formData.dropoffCountry, city: formData.dropoffCity, state: formData.dropoffState, zip: formData.dropoffZip}
          ],
          commodity: 'Racks',
          tag: 'Pro#1234',
          currency: 'USD'
        })
      };

    const handleCheck = () => {
        setIsSameDay(!isSameDay)
        console.log(isSameDay)
      };
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };
    
      const handleKeyUp = (event) => {
        const { name, value } = event.target
        console.log(name, ", ", value, ", ", value.length)
 
        if(name == "pickupCity"){
            setIsPickupAutoCoord(false)
        } else {
            setIsDropoffAutoCoord(false)
        }
        
        
        if(value.length >= 3){        

          getAutocomplete(value).then(
            data => {
              console.log("data is", data)
              var currentFocus
              let arr = []
              var flag = name
              var a, b, i, val = value;
              var jsElement = document.getElementById(name);
              /*close any already open lists of autocompleted values*/
              closeAllLists();
              currentFocus = -1;
              /*create a DIV element that will contain the items (values):*/
              a = document.createElement("DIV");
              a.setAttribute("id", name + "autocomplete-list");
              a.setAttribute("class", "autocomplete-items");
              /*append the DIV element as a child of the autocomplete container:*/
              jsElement.parentNode.appendChild(a);
    
              let locations = data
              console.log(val);
              console.log(locations.results)
    
              let trimLocArr = [];
              let arrIter = 0;
              for (i = 0; i < locations.results.length; i++) {
                
                if(locations.results[i].city && (locations.results[i].country == "United States" || locations.results[i].country == "Canada")){
    
                    // arr[arrIter] = locations.results[i].formatted;
    
                    let jsonObject;
                    if(locations.results[i].postcode){
                        jsonObject = {
                            display: `${locations.results[i].city}, ${locations.results[i].state}, ${locations.results[i].country} ${locations.results[i].postcode}`,
                            city: locations.results[i].city,
                            state: locations.results[i].state_code.toUpperCase(),
                            country: locations.results[i].country_code.toUpperCase(),
                            zip: "",
                            lat: locations.results[i].lat,
                            lon: locations.results[i].lon
                          };
                    } else {
                        jsonObject = {
                            display: `${locations.results[i].city}, ${locations.results[i].state}, ${locations.results[i].country}`,
                            city: locations.results[i].city,
                            state: locations.results[i].state_code.toUpperCase(),
                            country: locations.results[i].country_code.toUpperCase(),
                            zip: "",
                            lat: locations.results[i].lat,
                            lon: locations.results[i].lon
                          };
                    }
                    trimLocArr.push(jsonObject);
                    arrIter++;
                }      
            }
    
            for (i = 0; i < trimLocArr.length; i++){
                arr[i] = `${trimLocArr[i].city}, ${trimLocArr[i].state}, ${trimLocArr[i].country} ${trimLocArr[i].zip}` ;
            }
    
            console.log(trimLocArr);
            console.log(arr);
    
            // arr = [locations.results[0].formatted,locations.results[1].formatted];
    
            /*for each item in the array...*/
            for (i = 0; i < trimLocArr.length; i++) {
              /*check if the item starts with the same letters as the text field value:*/
              let display = trimLocArr[i].display;
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML = "<strong>" + display.substr(0, val.length) + "</strong>";
                b.innerHTML += display.substr(val.length);
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + display + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                let j = i;
                if(j == trimLocArr.length){
                  j = j-1
                }
                    b.addEventListener("click", function(e) {
                        /*insert the value for the autocomplete text field:*/
                            // console.log(locations.results[0].formatted);
                            // console.log(locations.results[i].formatted);
                            if(flag == "pickupCity"){   
                                // get UI elements
                                
                              console.log(trimLocArr[j].state)
    
                                setFormData({
                                  ...formData,
                                  pickupCity: trimLocArr[j].city,
                                  pickupState: trimLocArr[j].state,
                                  pickupCountry: trimLocArr[j].country,
                                  pickupZip: trimLocArr[j].zip
                                })

                                setCoords({
                                    ...coords,
                                    pLat: trimLocArr[j].lat,
                                    pLon: trimLocArr[j].lon
                                })

                                setIsPickupAutoCoord(true)
                                    
    
                            } else {
                              setFormData({
                                ...formData,
                                dropoffCity: trimLocArr[j].city,
                                dropoffState: trimLocArr[j].state,
                                dropoffCountry: trimLocArr[j].country,
                                dropoffZip: trimLocArr[j].zip
                              })

                              setCoords({
                                ...coords,
                                dLat: trimLocArr[j].lat,
                                dLon: trimLocArr[j].lon
                                })

                                setIsDropoffAutoCoord(true)
                            }
                        
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                        arr.length = 0;
                        trimLocArr.length = 0;
                });
                a.appendChild(b);
              
            }
            

            function closeAllLists(elmnt) {
              /*close all autocomplete lists in the document,
              except the one passed as an argument:*/
              
              var x = document.getElementsByClassName("autocomplete-items");
              for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != name) {
                x[i].parentNode.removeChild(x[i]);
              }
            }
          }
    
          document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });  
    
            }
          )
        }   
      }

      

      
      const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSearching(true)

        console.log(
            "flat mult", selectedAccount.eqMarkup.flat.mult,
            "flat add", selectedAccount.eqMarkup.flat.add
        )
    
          // Get Lane Rate API data
          fetch("https://lane-ojolp2uv4q-uc.a.run.app", requestOptions).then(
            response => response.json()
          ).then(
            lane => { 
              console.log('lane data is', lane)
              // Get Network Rate API data
              fetch("https://network-ojolp2uv4q-uc.a.run.app", requestOptions).then(
                response => response.json()
              ).then(
                network => {
    
                  //Calculate Confidence interval, Margin, check for Same Day shipment (This fn is in utils.js file)
                //   let rate = calcMargin(lane, network, formData, isSameDay)

                  let confCalc = getConfidenceCalc(lane, network)

                  let pickedRate = confCalc.rate;                
                  let lowConfidenceFlag = confCalc.conFlag;
              
                  let rateCalc = calcRate(pickedRate)

                  let rate = rateCalc.rate
                  let eqMarkup = rateCalc.eqMarkup
   
                onSubmitForm(rate, formData, pickedRate, eqMarkup, lowConfidenceFlag, isSameDay, coords, isPickupAutoCoord, isDropoffAutoCoord)
                setIsSearching(false)

                setIsPickupAutoCoord(false)
                setIsDropoffAutoCoord(false)
                
                }
              )        
            }
          )
             
      }

    useEffect(() => {
        if(selectedAcctTitle == "PTPRSAND"){
            setFormData((prev) => ({...prev, pickupCity: "Sandusky", pickupState: "OH"}))
        } else {
            setFormData((prev) => ({...prev, pickupCity: "", pickupState: "PICKUP"}))
        }

    }, [selectedAcctTitle])

    const getConfidenceCalc = (lane, network) => {
        // Confidence Interval Logic
        let pickedRate, lowConfidenceFlag

        if(lane.confidenceLevel <= 70 && network.confidenceLevel >= 70){
            pickedRate = network;
            console.log("using network rate");
          } else if (lane.confidenceLevel <= 70 && network.confidenceLevel <= 70) {
              if(lane.targetBuyRate > network.targetBuyRate){
                pickedRate = lane;
                lowConfidenceFlag = true;
                  console.log("using lane rate");
      
              } else {
                pickedRate = network;
                lowConfidenceFlag = true;
                  console.log("using network rate");
              }
          } else {
            pickedRate = lane;
              console.log("using lane rate");
          }

          return {rate: pickedRate, conFlag: lowConfidenceFlag}
    }

    const calcRate = (pickedRate) => {
        let eqMarkup, marginRate

        switch(formData.eqType) {
            case "VAN":
                eqMarkup = selectedAccount.eqMarkup.van.mult
                marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup
                console.log("VAN margin made rate " + marginRate)
                break
            case "FLATBED":
                if(selectedAcctTitle == "ICL") { 
                    eqMarkup = 1.11
                    marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup + 150
                } else {
                    eqMarkup = selectedAccount.eqMarkup.flat.mult
                    marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup
                }                       
                console.log("FLATBED margin made rate " + marginRate)
                break
            case "REEFER":
                eqMarkup = selectedAccount.eqMarkup.reefer.mult
                marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup
                console.log("REEFER margin made rate " + marginRate)
                break
            case "HOTSHOT":
                eqMarkup = selectedAccount.eqMarkup.hotshot.mult
                marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup;
                console.log("HOTSHOT margin made rate " + marginRate)
          }
        
          isSameDay == true ? marginRate = marginRate * 1.12 : marginRate = marginRate
      
          const rate = Number(marginRate).toFixed(2)

          return {rate: rate, eqMarkup: eqMarkup}
    }
    
    
    return(
            <form id="input" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-md-4 field">
                        <label className="calc-label">Equipment Type</label>
                        <label className="eqType-label">
                        <select className="custom-select" id="eqType" name="eqType" value={formData.eqType} onChange={handleChange}>
                            <option value="VAN">Van</option>
                            <option value="FLATBED">Flatbed</option>
                            <option value="REEFER">Reefer</option>
                            <option value="HOTSHOT">Hot Shot</option>
                        </select>
                      </label>
                    </div>
                    <div className="form-group col-md-4 field">
                        <label className="calc-label">Ship Date</label>
                        <input type="date" className="form-control" id="shipDate" name="shipDate" value={formData.shipDate} onChange={handleChange}/>
                    </div>
                    <div className="form-group col-md-4 field">
                        
                        <label className="form-check-label" htmlFor="sameDayCheck" >
                            <input className="form-check-input" type="checkbox" id="isSameDay" name="isSameDay" checked={isSameDay} onChange={handleCheck}/>
                            Same Day
                        </label>
                    
                    </div>
                </div>
                
                <div className="accordion" id="accordionPickup">
                    <div className="accordion-item">
                        <div className="form-row accordion-header" id="headingPickup">
                            <div className="autocomplete form-group col-md-6 field">
                                <label className="calc-label">Pickup City</label>
                                <input type="text" className="form-control" id="pickupCity" value={formData.pickupCity} onChange={handleChange} onKeyUp={handleKeyUp} name="pickupCity"  placeholder="Pickup City" autoComplete="off"/>
                            </div>                                   
                            <div className="form-group col-md-5 field">
                                <label className="calc-label">Pickup State</label>
                                <select className="form-control custom-select" id="pickupState" name="pickupState" placeholder="Pickup State" value={formData.pickupState} onChange={handleChange}>
                                    <option value="PICKUP">Pick-up State</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                    {/* <!-- Canada Codes --> */}
                                    <option value="AB">Alberta</option>
                                    <option value="BC">British Columbia</option>
                                    <option value="MB">Manitoba</option>
                                    <option value="NB">New Brunswick</option>
                                    <option value="NL">Newfoundland and Labrador</option>
                                    <option value="NT">Northwest Territories</option>
                                    <option value="NS">Nova Scotia</option>
                                    <option value="NU">Nunavut</option>
                                    <option value="ON">Ontario</option>
                                    <option value="PE">Prince Edward Island</option>
                                    <option value="QC">Quebec</option>
                                    <option value="SK">Saskatchewan</option>
                                    <option value="YT">Yukon</option>
                                    </select>
                            </div>
                            <div className="form-group col-md-1 field expand-button-container">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePickup" aria-expanded="true" aria-controls="collapsePickup" onclick="toggle(this)"> 
                                        +                                       
                                </button>
                            </div>
                        </div>
                        <div id="collapsePickup" className="form-row accordion-collapse collapse" aria-labelledby="headingPickup" data-bs-parent="#accordionPickup">
                            <div className="form-group col-md-1 field">
                            </div>
                            <div className="form-group col-md-5 field">
                                <label className="calc-label">Pickup Zip</label>
                                <input type="text" className="form-control" id="pickupZip" name="pickupZip" placeholder="Zip (optional)" value={formData.pickupZip} onChange={handleChange}/>
                            </div>
                            <div className="form-group col-md-5 field">
                                <label className="calc-label">Pickup Country</label>
                                <select className="form-control custom-select" id="pickupCountry" name="pickupCountry" placeholder="Country" value={formData.pickupCountry} onChange={handleChange}>
                                    <option value="US">United States</option>
                                    <option value="CA">Canada</option>
                                </select>
                            </div>
                            <div className="form-group col-md-1 field">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion" id="accordionDropoff">
                    <div className="accordion-item">
                        <div className="form-row accordion-header" id="headingDropoff">
                            <div className="autocomplete form-group col-md-6 field">
                                <label className="calc-label">Dropoff City</label>
                                <input type="text" className="form-control" id="dropoffCity" name="dropoffCity" placeholder="Dropoff City"  autoComplete="off" value={formData.dropoffCity} onChange={handleChange} onKeyUp={handleKeyUp} />
                            </div>
                            <div className="form-group col-md-5 field">
                                <label className="calc-label">Dropoff State</label>
                                <select className="form-control custom-select" id="dropoffState" name="dropoffState" placeholder="Dropoff State" value={formData.dropoffState} onChange={handleChange}>
                                    <option value="DROPOFF">Drop-off State</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                    {/* <!-- Canada Codes --> */}
                                    <option value="AB">Alberta</option>
                                    <option value="BC">British Columbia</option>
                                    <option value="MB">Manitoba</option>
                                    <option value="NB">New Brunswick</option>
                                    <option value="NL">Newfoundland and Labrador</option>
                                    <option value="NT">Northwest Territories</option>
                                    <option value="NS">Nova Scotia</option>
                                    <option value="NU">Nunavut</option>
                                    <option value="ON">Ontario</option>
                                    <option value="PE">Prince Edward Island</option>
                                    <option value="QC">Quebec</option>
                                    <option value="SK">Saskatchewan</option>
                                    <option value="YT">Yukon</option>
                                </select>
                            </div>
                            <div className="form-group col-md-1 field expand-button-container">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDropoff" aria-expanded="true" aria-controls="collapseDropoff" onclick="toggle(this)"> 
                                    +                                       
                                </button>
                            </div>
                        </div>
                        <div id="collapseDropoff" className="form-row accordion-collapse collapse" aria-labelledby="headingDropoff" data-bs-parent="#accordionDropoff">
                            <div className="form-group col-md-1 field">
                            </div>
                            <div className="form-group col-md-5 field">
                                <label className="calc-label">Dropoff Zip</label>
                                <input type="text" className="form-control" id="dropoffZip" name="dropoffZip" placeholder="Zip (optional)" value={formData.dropoffZip} onChange={handleChange}/>
                            </div>
                            <div className="form-group col-md-5 field">
                            <label className="calc-label">Dropoff Country</label>
                                <select className="form-control custom-select" id="dropoffCountry" name="dropoffCountry" placeholder="Country" value={formData.dropoffCountry} onChange={handleChange}>
                                    <option value="US">United States</option>
                                    <option value="CA">Canada</option>
                                </select>
                            </div>
                            <div className="form-group col-md-1 field">
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- <div className="autocomplete" style="width:300px;">
                    <input id="myInput" type="text" name="myCountry" placeholder="Country" autocomplete="off">
                </div>
                <div className="autocomplete" style="width:300px;">
                    <input id="myInput2" type="text" name="myCountry2" placeholder="Country" autocomplete="off">
                </div> --> */}
                <div className='vert-spacer'>
                    <button className="btn btn-primary btn-search" type="submit" id="submit">Get Bid</button>
                    <div className='loader-container'>
                    <Loader isLoading={isSearching} />
                    </div>
                </div>
                
                
            </form>     
    )
}