import { useState, useEffect } from "react"


export const Calculator = ({isPowerUser, calcModifiers}) => { 

    const [targetBuyRate, setTargetBuyRate] = useState()
    const [distance, setDistance] = useState()
    const [eqMarkup, setEqMarkup] = useState()
    const [calcRate, setCalcRate] = useState()
    const [sameDayMarkup, setSameDayMarkup] = useState()

    useEffect(() => {
        console.log('rebinding calculator inputs')
        calcModifiers.sameDayFlag == true ? setSameDayMarkup(1.12) : setSameDayMarkup(1)
        setTargetBuyRate(Number(calcModifiers.targetBuyRate))
        setDistance(Number(calcModifiers.distance))
        setEqMarkup(((Number(calcModifiers.eqMarkup)-1)*100).toFixed(1))
    }, [calcModifiers])

    useEffect(() => {

        setCalcRate((targetBuyRate * distance * ((eqMarkup/100)+1) * sameDayMarkup).toFixed(2))       

    }, [eqMarkup, targetBuyRate, sameDayMarkup])

    const resetModifiers = () => {
        setTargetBuyRate(Number(calcModifiers.targetBuyRate))
        setDistance(Number(calcModifiers.distance))
        setEqMarkup(((Number(calcModifiers.eqMarkup)-1)*100).toFixed(1))
    }

    let confidenceMessage = `Prediction confidence is ${calcModifiers.confidence}%`

    let sameDayMessage = `Same Day is set to True. Additional 12% markup is applied.`

    if(isPowerUser){

        console.log(calcModifiers)

        return (
            <section className="container content-container margin-top-spacer">   
                <div className="row content-row">                 
                    <div className="col-lg-12 col-custom-left"> 
                        <h5>Advanced Calculator</h5>
                        <p>{confidenceMessage}</p>         
                        <p>{calcModifiers.sameDayFlag == true ? sameDayMessage : ''}</p>         
                        <div className="row">
                            
                            <div className="col-sm-3">
                                <label className="calc-label">Target $ Per Mile:</label>
                                <input className="form-control" placeholder="Target Buy Rate" value={targetBuyRate} onChange={e => setTargetBuyRate(e.target.value)}/>
                            </div>
                            
                            <div className="col-sm-3">
                                <span className="style-operator">x</span>   
                                <label className="calc-label">Distance:</label>
                                <input readOnly={true} className="form-control" placeholder="Distance" value={distance}/>
                            </div>
                            
                            <div className="col-sm-3">
                                <span className="style-operator">x</span> 
                                <label className="calc-label">Equipment Markup %:</label>
                                <input className="form-control" placeholder="Equipment Markup" value={eqMarkup} onChange={e => setEqMarkup(e.target.value)}/>
                            </div>
                            
                            <div className="col-sm-3">
                                <span className="style-operator">=</span>   
                                <label className="calc-label">Output:</label>
                                <input readOnly={true} className="form-control" placeholder="Output" value={calcRate}/>
                            </div>
                            
                            <button className="btn btn-primary btn-calc" placeholder="reset" onClick={resetModifiers}>Reset</button>
                        </div>  
                    </div>
                </div>

            </section>
        )
    } else {
        return 
    }
}