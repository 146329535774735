import { useEffect, useState, useRef } from "react"
import { MapContainer, TileLayer, useMap, Popup, Marker, Polyline, GeoJSON } from 'react-leaflet'
import L from "leaflet"
import { Loader } from "./loader.js";

const MyGeoJson = ({myRouteSwitch, onFinished, onLoading}) => {

    const [data, setData] = useState();


    const map = useMap();

    const geoJsonLayer = useRef(null);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        const getRoutePath = async () => {
            // onLoading()

            console.log("myRouteSwitch is ", myRouteSwitch);
            let myAPIKey = process.env.REACT_APP_GEOAPIFY_KEY;
            const fromWaypoint = [myRouteSwitch.pLat, myRouteSwitch.pLon]; // latutude, longitude
            const toWaypoint = [myRouteSwitch.dLat, myRouteSwitch.dLon]; // latitude, longitude
            const url = `https://api.geoapify.com/v1/routing?waypoints=${fromWaypoint.join(',')}|${toWaypoint.join(',')}&mode=drive&details=instruction_details&apiKey=${myAPIKey}`;
    
            const response = await fetch(url)
            console.log("this is the response of the route api call", response)
            const result = await response.json()
            const rdata = await response.data
            
            console.log("this is the data of the route api call", rdata)
            console.log("this is the result of the route api call", result)
            const coordinates = await result.features[0].geometry.coordinates[0]
            
            setData(result)
            
            console.log("this is the coordinates of the route api call", coordinates)
    
            return await coordinates
        }
        if(!isEmpty(myRouteSwitch)){
            getRoutePath()
        }
        

    }, [myRouteSwitch])

    useEffect(() => {
        if(geoJsonLayer.current){
            geoJsonLayer.current.clearLayers().addData(data)
            onFinished()
        }
    }, [data])

    if (data) {

        const geojsonObject = L.geoJSON(data);
        map.fitBounds(geojsonObject.getBounds());
        console.log(geojsonObject);

        return <GeoJSON ref={geoJsonLayer} data={data} />
    } else {
        return null
    }

}

const MyMarker = ({coords, color}) => {

    // const icon = useRef(null)

    const myCustomColour = color

    const markerHtmlStyles = `
        background-color: ${myCustomColour};
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        left: -.75rem;
        top: -.4rem;
        position: relative;
        border-radius: 3rem 3rem 0;
        transform: rotate(45deg);
        border: 2px solid #FFFFFF`

        const myIcon = L.divIcon({
            className: "my-custom-pin",
            iconAnchor: [0, 24],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`
        })

    

    if(coords[0] != null){
        return (
        <Marker position={[coords[0], coords[1]]} icon={myIcon}>
            <Popup>
            Pickup City
            </Popup>
        </Marker>)
    } else {
        return null
    }
    
}

export const Map = ({routeSwitch, startLoader}) => { 
     const startMarker = { color: 'green' }
     const endMarker = { color: 'red' }

     const [isLoading, setIsLoading] = useState(false)

    // const [leafPath, setLeafPath ] = useState([
    //     [42.12, -80.08],
    //     [42.8864,-78.8784],
    //   ])

    useEffect(() => {

        if(startLoader){
            setIsLoading(true)
        }

    }, [startLoader])

    return (
        <div className={isLoading ? "map-blur" : ""}>
            <MapContainer center={[39.0997, -94.5786]} zoom={3} scrollWheelZoom={true} stlye={{width: "500px"}}>
                <Loader isLoading={isLoading} />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <Marker position={[42.12, -80.08]}>
                    <Popup>
                    Pickup City
                    </Popup>
                </Marker> */}
                <MyMarker coords={[routeSwitch.pLat, routeSwitch.pLon]} color="#5DC245"/>
                <MyMarker coords={[routeSwitch.dLat, routeSwitch.dLon]} color="#CF142B"/>
                {/* <Polyline pathOptions={purpleOptions} positions={leafPath} /> */}
                <MyGeoJson myRouteSwitch={routeSwitch} isLoading={isLoading} onFinished={() => setIsLoading(false)} onLoading={()=> setIsLoading(true)}/>
            </MapContainer>
        </div>
        
    )
     
}
