import { useState} from 'react'

export const columns = [
  {
    name: 'Equipment Type',
    selector: row => row.eqType,
    sortable: true,
  },
  {
    name: 'Ship Date',
    selector: row => row.shipDate,
    sortable: true,
  },
  {
    name: 'Pickup City',
    selector: row => row.pickupCity,
    sortable: true,
  },
  {
    name: 'Pickup State',
    selector: row => row.pickupState,
    sortable: true,
  },
  {
    name: 'Drop-off City',
    selector: row => row.dropoffCity,
    sortable: true,
  },
  {
    name: 'Drop-off State',
    selector: row => row.dropoffState,
    sortable: true,
  },
  {
    name: 'Rate',
    selector: row => row.rate,
    sortable: true,
  },
];

const defaultPricingJSON = {
    eqMarkup: {
        flat: {mult: 1.085, add: 0},
        van: {mult: 1.1, add: 0},
        reefer: {mult: 1.1, add: 0},
        hotshot: {mult: .7595, add: 0},
    },
    isSameDay: {
        mult: 1.12,
        add: 0
    },
    isMulti: {

    },
    // Make this a list of origin cities ??
    origin: [
        {
            city: "",
            state: "",
            country: "",
            mult: 1,
            add: ""
        },
        {
            city: "",
            state: "",
            mult: 1,
            add: ""
        }
    ],
    dest: [
        {
            city: "",
            state: "",
            country: "",
            mult: 1,
            add: ""
        },
        {
            city: "",
            state: "",
            mult: 1,
            add: ""
        }
    ],

    
}



export const calcMargin = (lane, network, formData, isSameDay) => {
    let pickedRate

    // Confidence Interval Logic
    if(lane.confidenceLevel <= 70 && network.confidenceLevel >= 70){
      pickedRate = network;
      console.log("using network rate");
    } else if (lane.confidenceLevel <= 70 && network.confidenceLevel <= 70) {
        if(lane.targetBuyRate > network.targetBuyRate){
          pickedRate = lane;
            console.log("using lane rate");

        } else {
          pickedRate = network;
            console.log("using network rate");
        }
    } else {
      pickedRate = lane;
        console.log("using lane rate");
    }

    let marginRate 
    // Applying margin
    switch(formData.eqType) {
      case "VAN":
        marginRate = pickedRate.targetBuyRate * pickedRate.distance * 1.1
          console.log("VAN margin made rate " + marginRate)
          break
      case "FLATBED":
        marginRate = pickedRate.targetBuyRate * pickedRate.distance * 1.085
          console.log("FLATBED margin made rate " + marginRate)
          break
      case "REEFER":
        marginRate = pickedRate.targetBuyRate * pickedRate.distance * 1.1
          console.log("REEFER margin made rate " + marginRate)
          break
      case "HOTSHOT":
          marginRate = pickedRate.targetBuyRate * pickedRate.distance * 1.085 * .70;
          console.log("HOTSHOT margin made rate " + marginRate)
    }
  
    isSameDay == true ? marginRate = marginRate * 1.12 : marginRate = marginRate

    const rate = Number(marginRate).toFixed(2)

    return rate
  }

  export function getAutocomplete(input) {           
    /* Create a new promise and send geocoding request */
    const promise = new Promise((resolve, reject) => {

      // Get an API Key on https://myprojects.geoapify.com
      const apiKey = "81bd5ac6e2f54cc28fee2ee5206521c2";

      console.log("logging input", input) 


      var url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(input)}&type=locality&filter=countrycode:us,ca&format=json&limit=8&apiKey=${apiKey}`;

      fetch(url)
        .then(response => {

          // check if the call was successful
          if (response.ok) {
            response.json().then(data => {
                console.log("logging data", data) 
                resolve(data)
            })
            ;
          } else {
            response.json().then(data => reject(data));
          }
        });
    });

    promise.then((data) => {
      // here we get address suggestions
      console.log(data);
    }, (err) => {
      if (!err.canceled) {
        console.log(err);
      }
    })

    return promise
  
}

export const coords = [
    [
        -78.878801,
        42.886463
    ],
    [
        -78.878865,
        42.886246
    ],
    [
        -78.878859,
        42.886189
    ],
    [
        -78.878789,
        42.886099
    ],
    [
        -78.878705,
        42.886034
    ],
    [
        -78.878522,
        42.885963
    ],
    [
        -78.878288,
        42.885921
    ],
    [
        -78.878601,
        42.88505
    ],
    [
        -78.878626,
        42.884976
    ],
    [
        -78.878649,
        42.884907
    ],
    [
        -78.878728,
        42.884659
    ],
    [
        -78.878979,
        42.883874
    ],
    [
        -78.878988,
        42.883803
    ],
    [
        -78.879573,
        42.883903
    ],
    [
        -78.879823,
        42.883948
    ],
    [
        -78.879751,
        42.883816
    ],
    [
        -78.879712,
        42.883742
    ],
    [
        -78.879269,
        42.882977
    ],
    [
        -78.878937,
        42.882383
    ],
    [
        -78.878798,
        42.882141
    ],
    [
        -78.87873,
        42.881991
    ],
    [
        -78.878681,
        42.881852
    ],
    [
        -78.878651,
        42.881731
    ],
    [
        -78.878609,
        42.881498
    ],
    [
        -78.878601,
        42.881309
    ],
    [
        -78.878606,
        42.881162
    ],
    [
        -78.87863,
        42.880959
    ],
    [
        -78.878659,
        42.880823
    ],
    [
        -78.878687,
        42.880696
    ],
    [
        -78.878828,
        42.880277
    ],
    [
        -78.878886,
        42.880044
    ],
    [
        -78.878921,
        42.879831
    ],
    [
        -78.878955,
        42.879502
    ],
    [
        -78.878944,
        42.879249
    ],
    [
        -78.878903,
        42.87891
    ],
    [
        -78.878812,
        42.878538
    ],
    [
        -78.878667,
        42.878064
    ],
    [
        -78.878584,
        42.877796
    ],
    [
        -78.878469,
        42.877413
    ],
    [
        -78.878369,
        42.877073
    ],
    [
        -78.878264,
        42.876737
    ],
    [
        -78.878166,
        42.876394
    ],
    [
        -78.878087,
        42.876067
    ],
    [
        -78.878055,
        42.875739
    ],
    [
        -78.878061,
        42.875406
    ],
    [
        -78.87817,
        42.874845
    ],
    [
        -78.878308,
        42.87439
    ],
    [
        -78.878592,
        42.87335
    ],
    [
        -78.878748,
        42.872797
    ],
    [
        -78.879025,
        42.871792
    ],
    [
        -78.879103,
        42.871434
    ],
    [
        -78.879145,
        42.870976
    ],
    [
        -78.879114,
        42.870499
    ],
    [
        -78.87902,
        42.870062
    ],
    [
        -78.878751,
        42.86946
    ],
    [
        -78.878527,
        42.869124
    ],
    [
        -78.87742,
        42.867675
    ],
    [
        -78.876805,
        42.866821
    ],
    [
        -78.876038,
        42.865754
    ],
    [
        -78.875255,
        42.864717
    ],
    [
        -78.874602,
        42.863915
    ],
    [
        -78.873741,
        42.862991
    ],
    [
        -78.873383,
        42.862647
    ],
    [
        -78.873252,
        42.862515
    ],
    [
        -78.871397,
        42.860681
    ],
    [
        -78.870758,
        42.859979
    ],
    [
        -78.870335,
        42.859437
    ],
    [
        -78.870098,
        42.859109
    ],
    [
        -78.870029,
        42.859019
    ],
    [
        -78.869538,
        42.858333
    ],
    [
        -78.86894,
        42.857511
    ],
    [
        -78.868376,
        42.856856
    ],
    [
        -78.867622,
        42.856158
    ],
    [
        -78.867065,
        42.855643
    ],
    [
        -78.866347,
        42.855002
    ],
    [
        -78.864669,
        42.853468
    ],
    [
        -78.864284,
        42.853046
    ],
    [
        -78.864163,
        42.852907
    ],
    [
        -78.86365,
        42.852265
    ],
    [
        -78.863223,
        42.851544
    ],
    [
        -78.86087,
        42.846928
    ],
    [
        -78.860516,
        42.846099
    ],
    [
        -78.86045,
        42.845937
    ],
    [
        -78.860164,
        42.84521
    ],
    [
        -78.859968,
        42.844737
    ],
    [
        -78.859765,
        42.844305
    ],
    [
        -78.859536,
        42.843846
    ],
    [
        -78.859206,
        42.843226
    ],
    [
        -78.858991,
        42.842808
    ],
    [
        -78.858778,
        42.842406
    ],
    [
        -78.858611,
        42.842088
    ],
    [
        -78.85843,
        42.841773
    ],
    [
        -78.858074,
        42.841182
    ],
    [
        -78.857666,
        42.840586
    ],
    [
        -78.857353,
        42.840119
    ],
    [
        -78.857132,
        42.83977
    ],
    [
        -78.856931,
        42.83941
    ],
    [
        -78.856772,
        42.83912
    ],
    [
        -78.856409,
        42.838445
    ],
    [
        -78.856082,
        42.837829
    ],
    [
        -78.855976,
        42.837631
    ],
    [
        -78.855569,
        42.836865
    ],
    [
        -78.855309,
        42.836363
    ],
    [
        -78.855172,
        42.836106
    ],
    [
        -78.854917,
        42.835629
    ],
    [
        -78.854225,
        42.834305
    ],
    [
        -78.853831,
        42.833572
    ],
    [
        -78.853017,
        42.832045
    ],
    [
        -78.852445,
        42.83095
    ],
    [
        -78.85196,
        42.830052
    ],
    [
        -78.85158,
        42.829303
    ],
    [
        -78.851153,
        42.828499
    ],
    [
        -78.850879,
        42.827993
    ],
    [
        -78.850664,
        42.827593
    ],
    [
        -78.850547,
        42.827373
    ],
    [
        -78.850459,
        42.827181
    ],
    [
        -78.850356,
        42.82696
    ],
    [
        -78.850276,
        42.826736
    ],
    [
        -78.850202,
        42.826502
    ],
    [
        -78.850141,
        42.826247
    ],
    [
        -78.850102,
        42.826045
    ],
    [
        -78.850056,
        42.825743
    ],
    [
        -78.850028,
        42.825397
    ],
    [
        -78.84999,
        42.824978
    ],
    [
        -78.849962,
        42.824632
    ],
    [
        -78.849937,
        42.82439
    ],
    [
        -78.849903,
        42.82408
    ],
    [
        -78.849864,
        42.823899
    ],
    [
        -78.849818,
        42.823711
    ],
    [
        -78.849772,
        42.823535
    ],
    [
        -78.849701,
        42.823286
    ],
    [
        -78.849036,
        42.821737
    ],
    [
        -78.848743,
        42.821125
    ],
    [
        -78.848527,
        42.820835
    ],
    [
        -78.84819,
        42.820194
    ],
    [
        -78.847985,
        42.819792
    ],
    [
        -78.847461,
        42.818762
    ],
    [
        -78.847013,
        42.81777
    ],
    [
        -78.846962,
        42.817669
    ],
    [
        -78.846744,
        42.817233
    ],
    [
        -78.846321,
        42.816395
    ],
    [
        -78.846187,
        42.816142
    ],
    [
        -78.846037,
        42.815858
    ],
    [
        -78.845691,
        42.815016
    ],
    [
        -78.845656,
        42.814851
    ],
    [
        -78.845454,
        42.813899
    ],
    [
        -78.845418,
        42.813561
    ],
    [
        -78.845404,
        42.813413
    ],
    [
        -78.845371,
        42.81307
    ],
    [
        -78.845382,
        42.812756
    ],
    [
        -78.845398,
        42.812374
    ],
    [
        -78.845515,
        42.811001
    ],
    [
        -78.845626,
        42.809814
    ],
    [
        -78.845636,
        42.80975
    ],
    [
        -78.845819,
        42.808184
    ],
    [
        -78.846027,
        42.806315
    ],
    [
        -78.84615,
        42.805272
    ],
    [
        -78.846242,
        42.804233
    ],
    [
        -78.846417,
        42.802129
    ],
    [
        -78.846474,
        42.801444
    ],
    [
        -78.84656,
        42.800412
    ],
    [
        -78.846627,
        42.799606
    ],
    [
        -78.846664,
        42.799288
    ],
    [
        -78.846712,
        42.798852
    ],
    [
        -78.846752,
        42.798373
    ],
    [
        -78.846779,
        42.798114
    ],
    [
        -78.84681,
        42.797786
    ],
    [
        -78.846849,
        42.797347
    ],
    [
        -78.84685,
        42.796595
    ],
    [
        -78.84685,
        42.796535
    ],
    [
        -78.84685,
        42.796473
    ],
    [
        -78.846847,
        42.795726
    ],
    [
        -78.846843,
        42.79499
    ],
    [
        -78.846821,
        42.794264
    ],
    [
        -78.846895,
        42.794084
    ],
    [
        -78.846891,
        42.793236
    ],
    [
        -78.847,
        42.792677
    ],
    [
        -78.847112,
        42.792287
    ],
    [
        -78.84716,
        42.792086
    ],
    [
        -78.847252,
        42.791842
    ],
    [
        -78.847359,
        42.791602
    ],
    [
        -78.847525,
        42.79136
    ],
    [
        -78.847693,
        42.7911
    ],
    [
        -78.847823,
        42.790818
    ],
    [
        -78.847872,
        42.790716
    ],
    [
        -78.847911,
        42.790602
    ],
    [
        -78.847953,
        42.790414
    ],
    [
        -78.847982,
        42.790272
    ],
    [
        -78.847988,
        42.790111
    ],
    [
        -78.847984,
        42.789982
    ],
    [
        -78.847975,
        42.789852
    ],
    [
        -78.847961,
        42.789694
    ],
    [
        -78.847945,
        42.789572
    ],
    [
        -78.847913,
        42.789486
    ],
    [
        -78.847886,
        42.789406
    ],
    [
        -78.847845,
        42.789317
    ],
    [
        -78.847761,
        42.789221
    ],
    [
        -78.847697,
        42.789165
    ],
    [
        -78.8476,
        42.789106
    ],
    [
        -78.847498,
        42.789063
    ],
    [
        -78.84738,
        42.78902
    ],
    [
        -78.847295,
        42.789
    ],
    [
        -78.847219,
        42.788988
    ],
    [
        -78.847123,
        42.788976
    ],
    [
        -78.846978,
        42.788961
    ],
    [
        -78.846785,
        42.788974
    ],
    [
        -78.846592,
        42.789028
    ],
    [
        -78.846468,
        42.789095
    ],
    [
        -78.84635,
        42.789189
    ],
    [
        -78.846294,
        42.789261
    ],
    [
        -78.846249,
        42.789319
    ],
    [
        -78.846168,
        42.789449
    ],
    [
        -78.846104,
        42.78961
    ],
    [
        -78.846003,
        42.789727
    ],
    [
        -78.845903,
        42.789814
    ],
    [
        -78.845751,
        42.789896
    ],
    [
        -78.845619,
        42.789941
    ],
    [
        -78.845493,
        42.789973
    ],
    [
        -78.845283,
        42.789985
    ],
    [
        -78.845102,
        42.789986
    ],
    [
        -78.844225,
        42.789994
    ],
    [
        -78.840483,
        42.790019
    ],
    [
        -78.839857,
        42.790031
    ],
    [
        -78.839393,
        42.790018
    ],
    [
        -78.839109,
        42.790016
    ],
    [
        -78.838686,
        42.789981
    ],
    [
        -78.838009,
        42.789894
    ],
    [
        -78.837607,
        42.789846
    ],
    [
        -78.834169,
        42.789394
    ],
    [
        -78.832911,
        42.789234
    ],
    [
        -78.831521,
        42.789186
    ],
    [
        -78.831312,
        42.789174
    ],
    [
        -78.831031,
        42.789166
    ],
    [
        -78.830644,
        42.789154
    ],
    [
        -78.829101,
        42.789144
    ],
    [
        -78.824912,
        42.789183
    ],
    [
        -78.823857,
        42.789223
    ],
    [
        -78.822956,
        42.78923
    ],
    [
        -78.822738,
        42.789235
    ],
    [
        -78.822134,
        42.789264
    ],
    [
        -78.821562,
        42.789308
    ],
    [
        -78.821044,
        42.78936
    ],
    [
        -78.820417,
        42.789442
    ],
    [
        -78.820247,
        42.78947
    ],
    [
        -78.820131,
        42.78949
    ],
    [
        -78.820204,
        42.789713
    ],
    [
        -78.820253,
        42.789882
    ],
    [
        -78.820285,
        42.79
    ],
    [
        -78.820301,
        42.790173
    ],
    [
        -78.820301,
        42.790331
    ],
    [
        -78.820303,
        42.790713
    ],
    [
        -78.82027,
        42.791283
    ],
    [
        -78.820268,
        42.791352
    ],
    [
        -78.820243,
        42.791822
    ],
    [
        -78.820213,
        42.792222
    ],
    [
        -78.820147,
        42.792501
    ],
    [
        -78.820067,
        42.792657
    ],
    [
        -78.819979,
        42.792771
    ],
    [
        -78.819877,
        42.792897
    ],
    [
        -78.819743,
        42.793031
    ],
    [
        -78.819587,
        42.793161
    ],
    [
        -78.81941,
        42.793287
    ],
    [
        -78.819233,
        42.793377
    ],
    [
        -78.819013,
        42.793464
    ],
    [
        -78.818702,
        42.793515
    ],
    [
        -78.818461,
        42.793527
    ],
    [
        -78.818257,
        42.793523
    ],
    [
        -78.818075,
        42.793511
    ],
    [
        -78.817898,
        42.793484
    ],
    [
        -78.817747,
        42.793444
    ],
    [
        -78.817613,
        42.793385
    ],
    [
        -78.817479,
        42.793322
    ],
    [
        -78.817372,
        42.793255
    ],
    [
        -78.817232,
        42.793149
    ],
    [
        -78.817131,
        42.793043
    ],
    [
        -78.817039,
        42.79294
    ],
    [
        -78.816975,
        42.792822
    ],
    [
        -78.816943,
        42.792724
    ],
    [
        -78.816822,
        42.792419
    ],
    [
        -78.816464,
        42.787572
    ],
    [
        -78.81646,
        42.787521
    ],
    [
        -78.816315,
        42.785559
    ],
    [
        -78.81623,
        42.7836
    ],
    [
        -78.816204,
        42.780942
    ],
    [
        -78.816281,
        42.778592
    ],
    [
        -78.81647,
        42.776305
    ],
    [
        -78.816856,
        42.772922
    ],
    [
        -78.81726,
        42.770484
    ],
    [
        -78.817286,
        42.77035
    ],
    [
        -78.817354,
        42.770038
    ],
    [
        -78.817583,
        42.768991
    ],
    [
        -78.817714,
        42.768385
    ],
    [
        -78.818005,
        42.767172
    ],
    [
        -78.818352,
        42.766396
    ],
    [
        -78.818573,
        42.765972
    ],
    [
        -78.819019,
        42.765279
    ],
    [
        -78.819544,
        42.764614
    ],
    [
        -78.819608,
        42.764522
    ],
    [
        -78.819988,
        42.764098
    ],
    [
        -78.820502,
        42.763554
    ],
    [
        -78.821096,
        42.762935
    ],
    [
        -78.821929,
        42.762254
    ],
    [
        -78.823182,
        42.761309
    ],
    [
        -78.824066,
        42.760666
    ],
    [
        -78.824701,
        42.760175
    ],
    [
        -78.825328,
        42.759708
    ],
    [
        -78.825886,
        42.759274
    ],
    [
        -78.826435,
        42.75882
    ],
    [
        -78.826933,
        42.758385
    ],
    [
        -78.827499,
        42.757856
    ],
    [
        -78.828718,
        42.756501
    ],
    [
        -78.830632,
        42.754289
    ],
    [
        -78.833842,
        42.750589
    ],
    [
        -78.835392,
        42.748779
    ],
    [
        -78.835714,
        42.748403
    ],
    [
        -78.836271,
        42.747753
    ],
    [
        -78.836793,
        42.747178
    ],
    [
        -78.837043,
        42.746902
    ],
    [
        -78.838005,
        42.745944
    ],
    [
        -78.838769,
        42.745276
    ],
    [
        -78.839558,
        42.74462
    ],
    [
        -78.840391,
        42.743996
    ],
    [
        -78.841103,
        42.743505
    ],
    [
        -78.841987,
        42.742956
    ],
    [
        -78.842707,
        42.742557
    ],
    [
        -78.843,
        42.742395
    ],
    [
        -78.84391,
        42.741916
    ],
    [
        -78.844863,
        42.741475
    ],
    [
        -78.849628,
        42.739521
    ],
    [
        -78.850982,
        42.738966
    ],
    [
        -78.852373,
        42.738392
    ],
    [
        -78.85342,
        42.737938
    ],
    [
        -78.854725,
        42.737346
    ],
    [
        -78.855798,
        42.736848
    ],
    [
        -78.859548,
        42.734957
    ],
    [
        -78.861557,
        42.733992
    ],
    [
        -78.86263,
        42.733525
    ],
    [
        -78.864218,
        42.732901
    ],
    [
        -78.865162,
        42.732548
    ],
    [
        -78.866363,
        42.732107
    ],
    [
        -78.870603,
        42.730688
    ],
    [
        -78.871616,
        42.730291
    ],
    [
        -78.872783,
        42.729837
    ],
    [
        -78.875994,
        42.728482
    ],
    [
        -78.877152,
        42.727971
    ],
    [
        -78.87844,
        42.727366
    ],
    [
        -78.880482,
        42.72637
    ],
    [
        -78.882113,
        42.725474
    ],
    [
        -78.884628,
        42.723955
    ],
    [
        -78.888207,
        42.721798
    ],
    [
        -78.889975,
        42.720751
    ],
    [
        -78.893297,
        42.718917
    ],
    [
        -78.895657,
        42.717725
    ],
    [
        -78.900189,
        42.715518
    ],
    [
        -78.901211,
        42.715
    ],
    [
        -78.902138,
        42.714521
    ],
    [
        -78.904764,
        42.713052
    ],
    [
        -78.906069,
        42.712263
    ],
    [
        -78.906578,
        42.711944
    ],
    [
        -78.907476,
        42.71138
    ],
    [
        -78.908837,
        42.710523
    ],
    [
        -78.908918,
        42.710472
    ],
    [
        -78.910231,
        42.709564
    ],
    [
        -78.911364,
        42.708801
    ],
    [
        -78.91152,
        42.708692
    ],
    [
        -78.916282,
        42.705357
    ],
    [
        -78.917252,
        42.704645
    ],
    [
        -78.920857,
        42.701913
    ],
    [
        -78.925501,
        42.698097
    ],
    [
        -78.927822,
        42.696159
    ],
    [
        -78.929466,
        42.694787
    ],
    [
        -78.929981,
        42.694356
    ],
    [
        -78.930785,
        42.693607
    ],
    [
        -78.932647,
        42.692053
    ],
    [
        -78.93447,
        42.69059
    ],
    [
        -78.935448,
        42.689745
    ],
    [
        -78.935899,
        42.689302
    ],
    [
        -78.936993,
        42.688262
    ],
    [
        -78.937809,
        42.687392
    ],
    [
        -78.938487,
        42.686571
    ],
    [
        -78.939456,
        42.685281
    ],
    [
        -78.939697,
        42.684956
    ],
    [
        -78.940195,
        42.684237
    ],
    [
        -78.942753,
        42.680603
    ],
    [
        -78.945267,
        42.677151
    ],
    [
        -78.947885,
        42.673769
    ],
    [
        -78.950626,
        42.670432
    ],
    [
        -78.950915,
        42.670077
    ],
    [
        -78.953945,
        42.666511
    ],
    [
        -78.955945,
        42.664302
    ],
    [
        -78.957249,
        42.662963
    ],
    [
        -78.957919,
        42.662251
    ],
    [
        -78.958606,
        42.661595
    ],
    [
        -78.959567,
        42.660648
    ],
    [
        -78.960476,
        42.659818
    ],
    [
        -78.961781,
        42.658628
    ],
    [
        -78.963,
        42.657605
    ],
    [
        -78.964348,
        42.656482
    ],
    [
        -78.965326,
        42.655636
    ],
    [
        -78.965754,
        42.655235
    ],
    [
        -78.966176,
        42.654841
    ],
    [
        -78.966948,
        42.654077
    ],
    [
        -78.968167,
        42.652877
    ],
    [
        -78.969755,
        42.651299
    ],
    [
        -78.971669,
        42.649582
    ],
    [
        -78.972441,
        42.648957
    ],
    [
        -78.973188,
        42.648383
    ],
    [
        -78.973952,
        42.647833
    ],
    [
        -78.974759,
        42.647278
    ],
    [
        -78.975737,
        42.646596
    ],
    [
        -78.976733,
        42.64587
    ],
    [
        -78.977505,
        42.645283
    ],
    [
        -78.978269,
        42.644683
    ],
    [
        -78.979162,
        42.643913
    ],
    [
        -78.980209,
        42.642972
    ],
    [
        -78.981419,
        42.641886
    ],
    [
        -78.982707,
        42.640807
    ],
    [
        -78.984209,
        42.639651
    ],
    [
        -78.985797,
        42.638559
    ],
    [
        -78.986863,
        42.637838
    ],
    [
        -78.987231,
        42.637611
    ],
    [
        -78.988184,
        42.636981
    ],
    [
        -78.990734,
        42.635154
    ],
    [
        -78.992183,
        42.634025
    ],
    [
        -78.992603,
        42.633678
    ],
    [
        -78.99341,
        42.63292
    ],
    [
        -78.994568,
        42.631878
    ],
    [
        -78.995204,
        42.631304
    ],
    [
        -78.998812,
        42.628056
    ],
    [
        -79.003618,
        42.623761
    ],
    [
        -79.005399,
        42.62224
    ],
    [
        -79.006279,
        42.621488
    ],
    [
        -79.007025,
        42.620899
    ],
    [
        -79.00924,
        42.619151
    ],
    [
        -79.011987,
        42.61713
    ],
    [
        -79.015034,
        42.615014
    ],
    [
        -79.018381,
        42.612645
    ],
    [
        -79.019755,
        42.611634
    ],
    [
        -79.021128,
        42.610497
    ],
    [
        -79.022372,
        42.609329
    ],
    [
        -79.02572,
        42.606296
    ],
    [
        -79.027651,
        42.604654
    ],
    [
        -79.029411,
        42.60339
    ],
    [
        -79.031814,
        42.601906
    ],
    [
        -79.033917,
        42.600705
    ],
    [
        -79.039281,
        42.598115
    ],
    [
        -79.045761,
        42.594987
    ],
    [
        -79.047478,
        42.594103
    ],
    [
        -79.048808,
        42.593313
    ],
    [
        -79.050139,
        42.592491
    ],
    [
        -79.051212,
        42.591733
    ],
    [
        -79.0531,
        42.590343
    ],
    [
        -79.055031,
        42.588732
    ],
    [
        -79.056619,
        42.587436
    ],
    [
        -79.057949,
        42.586393
    ],
    [
        -79.059408,
        42.585319
    ],
    [
        -79.061168,
        42.584118
    ],
    [
        -79.062329,
        42.583391
    ],
    [
        -79.063743,
        42.582539
    ],
    [
        -79.06546,
        42.581591
    ],
    [
        -79.067562,
        42.580548
    ],
    [
        -79.06915,
        42.579789
    ],
    [
        -79.07091,
        42.579063
    ],
    [
        -79.072626,
        42.578367
    ],
    [
        -79.074043,
        42.577799
    ],
    [
        -79.075502,
        42.577135
    ],
    [
        -79.076789,
        42.576535
    ],
    [
        -79.078034,
        42.575839
    ],
    [
        -79.079536,
        42.574986
    ],
    [
        -79.081252,
        42.573911
    ],
    [
        -79.08172,
        42.573577
    ],
    [
        -79.083269,
        42.572458
    ],
    [
        -79.08666,
        42.569582
    ],
    [
        -79.092515,
        42.564531
    ],
    [
        -79.093144,
        42.563989
    ],
    [
        -79.093183,
        42.563956
    ],
    [
        -79.09355,
        42.563634
    ],
    [
        -79.094973,
        42.562383
    ],
    [
        -79.102324,
        42.555926
    ],
    [
        -79.103353,
        42.555074
    ],
    [
        -79.103861,
        42.554628
    ],
    [
        -79.104425,
        42.554133
    ],
    [
        -79.105517,
        42.553228
    ],
    [
        -79.108697,
        42.550593
    ],
    [
        -79.111407,
        42.548493
    ],
    [
        -79.114734,
        42.545781
    ],
    [
        -79.120348,
        42.541225
    ],
    [
        -79.132665,
        42.531138
    ],
    [
        -79.134897,
        42.529335
    ],
    [
        -79.136399,
        42.528165
    ],
    [
        -79.137986,
        42.52709
    ],
    [
        -79.147814,
        42.520669
    ],
    [
        -79.148758,
        42.520068
    ],
    [
        -79.149917,
        42.519404
    ],
    [
        -79.152517,
        42.518105
    ],
    [
        -79.153495,
        42.517619
    ],
    [
        -79.15614,
        42.516304
    ],
    [
        -79.158028,
        42.515355
    ],
    [
        -79.159444,
        42.514691
    ],
    [
        -79.160689,
        42.514216
    ],
    [
        -79.161933,
        42.513837
    ],
    [
        -79.163392,
        42.513425
    ],
    [
        -79.164894,
        42.513141
    ],
    [
        -79.166225,
        42.512983
    ],
    [
        -79.167341,
        42.512875
    ],
    [
        -79.168508,
        42.512799
    ],
    [
        -79.170482,
        42.512704
    ],
    [
        -79.1714,
        42.512685
    ],
    [
        -79.172021,
        42.512667
    ],
    [
        -79.173214,
        42.512627
    ],
    [
        -79.177469,
        42.512666
    ],
    [
        -79.181717,
        42.512685
    ],
    [
        -79.188764,
        42.512679
    ],
    [
        -79.1923,
        42.512647
    ],
    [
        -79.193553,
        42.512635
    ],
    [
        -79.19454,
        42.512584
    ],
    [
        -79.195502,
        42.512508
    ],
    [
        -79.196592,
        42.512394
    ],
    [
        -79.197416,
        42.512261
    ],
    [
        -79.198205,
        42.512128
    ],
    [
        -79.199192,
        42.511913
    ],
    [
        -79.200076,
        42.511698
    ],
    [
        -79.201055,
        42.511426
    ],
    [
        -79.202111,
        42.511085
    ],
    [
        -79.203003,
        42.510737
    ],
    [
        -79.206805,
        42.509227
    ],
    [
        -79.20896,
        42.508389
    ],
    [
        -79.215852,
        42.50565
    ],
    [
        -79.222641,
        42.502941
    ],
    [
        -79.229731,
        42.500138
    ],
    [
        -79.233121,
        42.498784
    ],
    [
        -79.233954,
        42.498423
    ],
    [
        -79.234803,
        42.498037
    ],
    [
        -79.235567,
        42.49767
    ],
    [
        -79.236297,
        42.497316
    ],
    [
        -79.237155,
        42.496873
    ],
    [
        -79.238108,
        42.496366
    ],
    [
        -79.239164,
        42.495746
    ],
    [
        -79.239639,
        42.495464
    ],
    [
        -79.240305,
        42.495069
    ],
    [
        -79.242769,
        42.49348
    ],
    [
        -79.247687,
        42.490278
    ],
    [
        -79.253248,
        42.486645
    ],
    [
        -79.258862,
        42.482999
    ],
    [
        -79.260836,
        42.481708
    ],
    [
        -79.261514,
        42.481277
    ],
    [
        -79.262055,
        42.480948
    ],
    [
        -79.262613,
        42.480625
    ],
    [
        -79.263102,
        42.480372
    ],
    [
        -79.263685,
        42.480056
    ],
    [
        -79.264261,
        42.479777
    ],
    [
        -79.269222,
        42.477384
    ],
    [
        -79.275616,
        42.474314
    ],
    [
        -79.282654,
        42.470927
    ],
    [
        -79.288662,
        42.468021
    ],
    [
        -79.291872,
        42.46647
    ],
    [
        -79.293391,
        42.465716
    ],
    [
        -79.294198,
        42.465355
    ],
    [
        -79.295029,
        42.465016
    ],
    [
        -79.295692,
        42.464767
    ],
    [
        -79.297486,
        42.464133
    ],
    [
        -79.298962,
        42.463697
    ],
    [
        -79.299616,
        42.463519
    ],
    [
        -79.300412,
        42.463304
    ],
    [
        -79.301872,
        42.462987
    ],
    [
        -79.30267,
        42.462835
    ],
    [
        -79.30412,
        42.462588
    ],
    [
        -79.3057,
        42.462386
    ],
    [
        -79.305797,
        42.462378
    ],
    [
        -79.306824,
        42.462272
    ],
    [
        -79.308575,
        42.462114
    ],
    [
        -79.311116,
        42.461898
    ],
    [
        -79.31214,
        42.461817
    ],
    [
        -79.316557,
        42.461398
    ],
    [
        -79.317418,
        42.461288
    ],
    [
        -79.318607,
        42.461133
    ],
    [
        -79.319872,
        42.460941
    ],
    [
        -79.321162,
        42.460716
    ],
    [
        -79.323719,
        42.460263
    ],
    [
        -79.324709,
        42.460071
    ],
    [
        -79.327252,
        42.459639
    ],
    [
        -79.32841,
        42.459524
    ],
    [
        -79.329569,
        42.459378
    ],
    [
        -79.330608,
        42.459289
    ],
    [
        -79.331784,
        42.459207
    ],
    [
        -79.333709,
        42.459142
    ],
    [
        -79.333968,
        42.45914
    ],
    [
        -79.336933,
        42.45915
    ],
    [
        -79.343508,
        42.459157
    ],
    [
        -79.344795,
        42.45915
    ],
    [
        -79.346195,
        42.45915
    ],
    [
        -79.348169,
        42.459131
    ],
    [
        -79.349448,
        42.459112
    ],
    [
        -79.350718,
        42.459074
    ],
    [
        -79.351602,
        42.45903
    ],
    [
        -79.352692,
        42.458947
    ],
    [
        -79.353791,
        42.458846
    ],
    [
        -79.354838,
        42.458707
    ],
    [
        -79.355911,
        42.458549
    ],
    [
        -79.356898,
        42.458371
    ],
    [
        -79.357876,
        42.458169
    ],
    [
        -79.358906,
        42.457941
    ],
    [
        -79.360322,
        42.457561
    ],
    [
        -79.361138,
        42.457258
    ],
    [
        -79.361431,
        42.457166
    ],
    [
        -79.362588,
        42.456839
    ],
    [
        -79.363661,
        42.45644
    ],
    [
        -79.3647,
        42.456022
    ],
    [
        -79.365927,
        42.455477
    ],
    [
        -79.366845,
        42.455053
    ],
    [
        -79.367626,
        42.454654
    ],
    [
        -79.369008,
        42.453869
    ],
    [
        -79.369661,
        42.453451
    ],
    [
        -79.370433,
        42.452957
    ],
    [
        -79.37118,
        42.452425
    ],
    [
        -79.372193,
        42.451659
    ],
    [
        -79.372991,
        42.451
    ],
    [
        -79.373678,
        42.450386
    ],
    [
        -79.374467,
        42.4496
    ],
    [
        -79.375042,
        42.449024
    ],
    [
        -79.375686,
        42.448296
    ],
    [
        -79.37639,
        42.447466
    ],
    [
        -79.377368,
        42.44613
    ],
    [
        -79.378089,
        42.445123
    ],
    [
        -79.378536,
        42.444489
    ],
    [
        -79.37899,
        42.443843
    ],
    [
        -79.380252,
        42.442216
    ],
    [
        -79.381033,
        42.441215
    ],
    [
        -79.381703,
        42.440448
    ],
    [
        -79.38281,
        42.439181
    ],
    [
        -79.383951,
        42.437991
    ],
    [
        -79.385548,
        42.436407
    ],
    [
        -79.387144,
        42.434925
    ],
    [
        -79.391075,
        42.431447
    ],
    [
        -79.392981,
        42.429787
    ],
    [
        -79.394165,
        42.428697
    ],
    [
        -79.395453,
        42.427449
    ],
    [
        -79.396551,
        42.426322
    ],
    [
        -79.398045,
        42.42463
    ],
    [
        -79.399847,
        42.422628
    ],
    [
        -79.400637,
        42.421779
    ],
    [
        -79.401169,
        42.421253
    ],
    [
        -79.401684,
        42.420771
    ],
    [
        -79.402122,
        42.420398
    ],
    [
        -79.402894,
        42.419764
    ],
    [
        -79.403366,
        42.419415
    ],
    [
        -79.404117,
        42.418874
    ],
    [
        -79.404336,
        42.418718
    ],
    [
        -79.40504,
        42.41825
    ],
    [
        -79.405958,
        42.417698
    ],
    [
        -79.406679,
        42.41728
    ],
    [
        -79.407598,
        42.416799
    ],
    [
        -79.408851,
        42.416203
    ],
    [
        -79.410078,
        42.415683
    ],
    [
        -79.411254,
        42.415233
    ],
    [
        -79.412593,
        42.41479
    ],
    [
        -79.413949,
        42.414416
    ],
    [
        -79.415383,
        42.414067
    ],
    [
        -79.416833,
        42.413789
    ],
    [
        -79.418026,
        42.413599
    ],
    [
        -79.419331,
        42.413446
    ],
    [
        -79.420429,
        42.413339
    ],
    [
        -79.423827,
        42.413131
    ],
    [
        -79.425754,
        42.413012
    ],
    [
        -79.42664,
        42.412951
    ],
    [
        -79.427431,
        42.412878
    ],
    [
        -79.428084,
        42.412799
    ],
    [
        -79.428928,
        42.412716
    ],
    [
        -79.4297,
        42.412589
    ],
    [
        -79.430895,
        42.412383
    ],
    [
        -79.432002,
        42.41213
    ],
    [
        -79.433055,
        42.411899
    ],
    [
        -79.434208,
        42.411582
    ],
    [
        -79.43515,
        42.411262
    ],
    [
        -79.43601,
        42.410972
    ],
    [
        -79.436787,
        42.41067
    ],
    [
        -79.437666,
        42.410317
    ],
    [
        -79.438483,
        42.409938
    ],
    [
        -79.439304,
        42.409524
    ],
    [
        -79.440076,
        42.409118
    ],
    [
        -79.440832,
        42.40866
    ],
    [
        -79.441491,
        42.408211
    ],
    [
        -79.442269,
        42.407692
    ],
    [
        -79.442978,
        42.407178
    ],
    [
        -79.44376,
        42.40653
    ],
    [
        -79.444678,
        42.405687
    ],
    [
        -79.445292,
        42.40501
    ],
    [
        -79.445898,
        42.404485
    ],
    [
        -79.446661,
        42.403867
    ],
    [
        -79.447334,
        42.403299
    ],
    [
        -79.448077,
        42.402755
    ],
    [
        -79.448813,
        42.402263
    ],
    [
        -79.449837,
        42.401599
    ],
    [
        -79.450681,
        42.401139
    ],
    [
        -79.45168,
        42.400589
    ],
    [
        -79.45259,
        42.400188
    ],
    [
        -79.453712,
        42.399693
    ],
    [
        -79.454384,
        42.399421
    ],
    [
        -79.460246,
        42.397463
    ],
    [
        -79.461319,
        42.397089
    ],
    [
        -79.462521,
        42.396645
    ],
    [
        -79.463662,
        42.396208
    ],
    [
        -79.464933,
        42.395682
    ],
    [
        -79.46553,
        42.395433
    ],
    [
        -79.466727,
        42.394902
    ],
    [
        -79.470821,
        42.393007
    ],
    [
        -79.476302,
        42.390435
    ],
    [
        -79.477301,
        42.38997
    ],
    [
        -79.47803,
        42.389609
    ],
    [
        -79.478923,
        42.389152
    ],
    [
        -79.479841,
        42.388658
    ],
    [
        -79.480751,
        42.38817
    ],
    [
        -79.481498,
        42.387745
    ],
    [
        -79.482373,
        42.387213
    ],
    [
        -79.483215,
        42.386718
    ],
    [
        -79.486931,
        42.384391
    ],
    [
        -79.487952,
        42.383783
    ],
    [
        -79.489043,
        42.383168
    ],
    [
        -79.490107,
        42.382616
    ],
    [
        -79.491334,
        42.38202
    ],
    [
        -79.492553,
        42.381463
    ],
    [
        -79.497445,
        42.37951
    ],
    [
        -79.49851,
        42.379072
    ],
    [
        -79.499548,
        42.378616
    ],
    [
        -79.500441,
        42.378197
    ],
    [
        -79.501488,
        42.377684
    ],
    [
        -79.502681,
        42.377069
    ],
    [
        -79.504071,
        42.376327
    ],
    [
        -79.505762,
        42.37542
    ],
    [
        -79.507934,
        42.374253
    ],
    [
        -79.50947,
        42.373416
    ],
    [
        -79.510037,
        42.373112
    ],
    [
        -79.512285,
        42.371793
    ],
    [
        -79.515229,
        42.369986
    ],
    [
        -79.515839,
        42.369612
    ],
    [
        -79.519152,
        42.367348
    ],
    [
        -79.524662,
        42.363613
    ],
    [
        -79.524873,
        42.363469
    ],
    [
        -79.526139,
        42.362604
    ],
    [
        -79.527349,
        42.361805
    ],
    [
        -79.531426,
        42.359275
    ],
    [
        -79.535477,
        42.356985
    ],
    [
        -79.536653,
        42.356351
    ],
    [
        -79.538103,
        42.355577
    ],
    [
        -79.541271,
        42.353985
    ],
    [
        -79.544009,
        42.352704
    ],
    [
        -79.550085,
        42.350021
    ],
    [
        -79.55236,
        42.349113
    ],
    [
        -79.553991,
        42.348517
    ],
    [
        -79.555124,
        42.348149
    ],
    [
        -79.556385,
        42.347769
    ],
    [
        -79.557982,
        42.347331
    ],
    [
        -79.560471,
        42.346766
    ],
    [
        -79.562265,
        42.346392
    ],
    [
        -79.569354,
        42.344895
    ],
    [
        -79.570462,
        42.34466
    ],
    [
        -79.571637,
        42.344356
    ],
    [
        -79.572839,
        42.344032
    ],
    [
        -79.574101,
        42.343632
    ],
    [
        -79.575096,
        42.343274
    ],
    [
        -79.575474,
        42.343163
    ],
    [
        -79.576701,
        42.342656
    ],
    [
        -79.577688,
        42.342211
    ],
    [
        -79.578924,
        42.341602
    ],
    [
        -79.579946,
        42.341063
    ],
    [
        -79.580884,
        42.3405
    ],
    [
        -79.587673,
        42.336417
    ],
    [
        -79.59077,
        42.334474
    ],
    [
        -79.592106,
        42.333605
    ],
    [
        -79.593407,
        42.332851
    ],
    [
        -79.595071,
        42.331865
    ],
    [
        -79.597565,
        42.330344
    ],
    [
        -79.599129,
        42.329452
    ],
    [
        -79.602408,
        42.32746
    ],
    [
        -79.6033,
        42.32692
    ],
    [
        -79.604253,
        42.326311
    ],
    [
        -79.605369,
        42.32555
    ],
    [
        -79.606262,
        42.324909
    ],
    [
        -79.607446,
        42.323982
    ],
    [
        -79.611746,
        42.320473
    ],
    [
        -79.615145,
        42.317725
    ],
    [
        -79.615995,
        42.31709
    ],
    [
        -79.616819,
        42.316507
    ],
    [
        -79.617565,
        42.315999
    ],
    [
        -79.618364,
        42.315497
    ],
    [
        -79.61917,
        42.314983
    ],
    [
        -79.620054,
        42.314475
    ],
    [
        -79.621136,
        42.313911
    ],
    [
        -79.623196,
        42.312927
    ],
    [
        -79.624149,
        42.312508
    ],
    [
        -79.625222,
        42.31207
    ],
    [
        -79.626406,
        42.311607
    ],
    [
        -79.627136,
        42.311334
    ],
    [
        -79.628844,
        42.310756
    ],
    [
        -79.634824,
        42.308829
    ],
    [
        -79.635215,
        42.308709
    ],
    [
        -79.635878,
        42.308494
    ],
    [
        -79.637701,
        42.307912
    ],
    [
        -79.639032,
        42.307436
    ],
    [
        -79.640276,
        42.306941
    ],
    [
        -79.641426,
        42.306453
    ],
    [
        -79.642396,
        42.306008
    ],
    [
        -79.644765,
        42.304866
    ],
    [
        -79.648636,
        42.302974
    ],
    [
        -79.650576,
        42.302047
    ],
    [
        -79.651975,
        42.301412
    ],
    [
        -79.653065,
        42.300936
    ],
    [
        -79.654567,
        42.300314
    ],
    [
        -79.656395,
        42.299584
    ],
    [
        -79.658086,
        42.298962
    ],
    [
        -79.659391,
        42.298498
    ],
    [
        -79.661768,
        42.297699
    ],
    [
        -79.668686,
        42.295438
    ],
    [
        -79.670343,
        42.294899
    ],
    [
        -79.671218,
        42.294594
    ],
    [
        -79.672231,
        42.294239
    ],
    [
        -79.673476,
        42.293762
    ],
    [
        -79.675072,
        42.293159
    ],
    [
        -79.677561,
        42.292124
    ],
    [
        -79.678643,
        42.291648
    ],
    [
        -79.67997,
        42.291051
    ],
    [
        -79.681226,
        42.29048
    ],
    [
        -79.682634,
        42.289807
    ],
    [
        -79.683904,
        42.289185
    ],
    [
        -79.685492,
        42.288359
    ],
    [
        -79.686728,
        42.287705
    ],
    [
        -79.688007,
        42.286994
    ],
    [
        -79.688668,
        42.286632
    ],
    [
        -79.689904,
        42.285889
    ],
    [
        -79.691517,
        42.284937
    ],
    [
        -79.696924,
        42.281495
    ],
    [
        -79.697954,
        42.280892
    ],
    [
        -79.698984,
        42.28032
    ],
    [
        -79.700272,
        42.279673
    ],
    [
        -79.701619,
        42.279056
    ],
    [
        -79.702804,
        42.278555
    ],
    [
        -79.704332,
        42.277964
    ],
    [
        -79.706203,
        42.277348
    ],
    [
        -79.707319,
        42.277012
    ],
    [
        -79.708434,
        42.276713
    ],
    [
        -79.713318,
        42.275583
    ],
    [
        -79.715421,
        42.275109
    ],
    [
        -79.717596,
        42.274605
    ],
    [
        -79.720767,
        42.273884
    ],
    [
        -79.722119,
        42.273566
    ],
    [
        -79.723246,
        42.273283
    ],
    [
        -79.724294,
        42.272995
    ],
    [
        -79.725443,
        42.272637
    ],
    [
        -79.726585,
        42.272251
    ],
    [
        -79.7277,
        42.271841
    ],
    [
        -79.728966,
        42.271324
    ],
    [
        -79.730365,
        42.270695
    ],
    [
        -79.731172,
        42.270284
    ],
    [
        -79.732426,
        42.269645
    ],
    [
        -79.73337,
        42.269105
    ],
    [
        -79.734481,
        42.26842
    ],
    [
        -79.735653,
        42.267677
    ],
    [
        -79.736716,
        42.26699
    ],
    [
        -79.737306,
        42.266594
    ],
    [
        -79.738349,
        42.265922
    ],
    [
        -79.73897,
        42.265519
    ],
    [
        -79.740062,
        42.264823
    ],
    [
        -79.74073,
        42.264405
    ],
    [
        -79.740829,
        42.264348
    ],
    [
        -79.742055,
        42.263663
    ],
    [
        -79.742814,
        42.263258
    ],
    [
        -79.74347,
        42.262937
    ],
    [
        -79.744715,
        42.262346
    ],
    [
        -79.745487,
        42.261997
    ],
    [
        -79.748989,
        42.260533
    ],
    [
        -79.752174,
        42.259202
    ],
    [
        -79.752903,
        42.258878
    ],
    [
        -79.753719,
        42.25849
    ],
    [
        -79.75462,
        42.258027
    ],
    [
        -79.755427,
        42.257582
    ],
    [
        -79.756336,
        42.257042
    ],
    [
        -79.757358,
        42.256381
    ],
    [
        -79.758259,
        42.255778
    ],
    [
        -79.759409,
        42.254933
    ],
    [
        -79.760225,
        42.25426
    ],
    [
        -79.761626,
        42.252968
    ],
    [
        -79.76191,
        42.252729
    ],
    [
        -79.762281,
        42.25242
    ],
    [
        -79.763226,
        42.251587
    ],
    [
        -79.763871,
        42.25098
    ],
    [
        -79.76452,
        42.250376
    ],
    [
        -79.765361,
        42.249647
    ],
    [
        -79.765945,
        42.249136
    ],
    [
        -79.767094,
        42.248137
    ],
    [
        -79.768102,
        42.24726
    ],
    [
        -79.768793,
        42.246662
    ],
    [
        -79.770292,
        42.24536
    ],
    [
        -79.770989,
        42.24476
    ],
    [
        -79.771347,
        42.244456
    ],
    [
        -79.77144,
        42.244373
    ],
    [
        -79.771472,
        42.244344
    ],
    [
        -79.771766,
        42.244083
    ],
    [
        -79.772635,
        42.243332
    ],
    [
        -79.773688,
        42.242438
    ],
    [
        -79.774324,
        42.24188
    ],
    [
        -79.774885,
        42.241366
    ],
    [
        -79.77521,
        42.241096
    ],
    [
        -79.775597,
        42.240757
    ],
    [
        -79.775944,
        42.240431
    ],
    [
        -79.776297,
        42.240082
    ],
    [
        -79.776569,
        42.239806
    ],
    [
        -79.77685,
        42.239509
    ],
    [
        -79.77714,
        42.239181
    ],
    [
        -79.77739,
        42.238883
    ],
    [
        -79.77766,
        42.238547
    ],
    [
        -79.778088,
        42.237955
    ],
    [
        -79.778284,
        42.237674
    ],
    [
        -79.77851,
        42.237316
    ],
    [
        -79.778716,
        42.236972
    ],
    [
        -79.778916,
        42.23662
    ],
    [
        -79.779119,
        42.236234
    ],
    [
        -79.779324,
        42.235814
    ],
    [
        -79.779426,
        42.235603
    ],
    [
        -79.779589,
        42.235216
    ],
    [
        -79.779749,
        42.234795
    ],
    [
        -79.78015,
        42.233775
    ],
    [
        -79.780434,
        42.233008
    ],
    [
        -79.780661,
        42.232285
    ],
    [
        -79.780861,
        42.231721
    ],
    [
        -79.781736,
        42.229307
    ],
    [
        -79.781878,
        42.228954
    ],
    [
        -79.782077,
        42.228514
    ],
    [
        -79.782271,
        42.228103
    ],
    [
        -79.782543,
        42.227585
    ],
    [
        -79.782718,
        42.227279
    ],
    [
        -79.782901,
        42.226976
    ],
    [
        -79.783168,
        42.226575
    ],
    [
        -79.783443,
        42.226183
    ],
    [
        -79.783665,
        42.225877
    ],
    [
        -79.783924,
        42.225548
    ],
    [
        -79.784239,
        42.22517
    ],
    [
        -79.784574,
        42.224792
    ],
    [
        -79.784887,
        42.224456
    ],
    [
        -79.785221,
        42.224127
    ],
    [
        -79.78565,
        42.223723
    ],
    [
        -79.786136,
        42.223291
    ],
    [
        -79.786552,
        42.222951
    ],
    [
        -79.787179,
        42.222464
    ],
    [
        -79.788088,
        42.22177
    ],
    [
        -79.788831,
        42.221204
    ],
    [
        -79.789849,
        42.220424
    ],
    [
        -79.791606,
        42.219083
    ],
    [
        -79.7934,
        42.217713
    ],
    [
        -79.795441,
        42.216147
    ],
    [
        -79.796816,
        42.215097
    ],
    [
        -79.798523,
        42.213793
    ],
    [
        -79.800058,
        42.212613
    ],
    [
        -79.802144,
        42.211011
    ],
    [
        -79.803769,
        42.209768
    ],
    [
        -79.80502,
        42.208816
    ],
    [
        -79.806643,
        42.207566
    ],
    [
        -79.808691,
        42.206
    ],
    [
        -79.809687,
        42.205242
    ],
    [
        -79.810251,
        42.204823
    ],
    [
        -79.810608,
        42.20455
    ],
    [
        -79.811548,
        42.203825
    ],
    [
        -79.812716,
        42.202933
    ],
    [
        -79.813257,
        42.202508
    ],
    [
        -79.813748,
        42.20212
    ],
    [
        -79.814142,
        42.201794
    ],
    [
        -79.814542,
        42.201438
    ],
    [
        -79.815127,
        42.200938
    ],
    [
        -79.815933,
        42.200184
    ],
    [
        -79.817291,
        42.198893
    ],
    [
        -79.817844,
        42.198358
    ],
    [
        -79.818546,
        42.19769
    ],
    [
        -79.819392,
        42.196928
    ],
    [
        -79.819908,
        42.196452
    ],
    [
        -79.820565,
        42.19584
    ],
    [
        -79.821168,
        42.195309
    ],
    [
        -79.821998,
        42.194565
    ],
    [
        -79.822679,
        42.193972
    ],
    [
        -79.824154,
        42.19278
    ],
    [
        -79.82492,
        42.192174
    ],
    [
        -79.825574,
        42.191652
    ],
    [
        -79.82668,
        42.190763
    ],
    [
        -79.827898,
        42.189786
    ],
    [
        -79.829951,
        42.188179
    ],
    [
        -79.830437,
        42.187802
    ],
    [
        -79.830993,
        42.18737
    ],
    [
        -79.831462,
        42.186982
    ],
    [
        -79.831843,
        42.186662
    ],
    [
        -79.832188,
        42.186397
    ],
    [
        -79.832638,
        42.186047
    ],
    [
        -79.833014,
        42.185758
    ],
    [
        -79.833678,
        42.185241
    ],
    [
        -79.83418,
        42.184841
    ],
    [
        -79.834721,
        42.184426
    ],
    [
        -79.835207,
        42.184042
    ],
    [
        -79.835631,
        42.183712
    ],
    [
        -79.835972,
        42.183443
    ],
    [
        -79.836416,
        42.183097
    ],
    [
        -79.837511,
        42.182213
    ],
    [
        -79.837983,
        42.181805
    ],
    [
        -79.838458,
        42.181375
    ],
    [
        -79.838827,
        42.181019
    ],
    [
        -79.839255,
        42.18059
    ],
    [
        -79.839595,
        42.180232
    ],
    [
        -79.839916,
        42.179878
    ],
    [
        -79.840261,
        42.179504
    ],
    [
        -79.840775,
        42.178939
    ],
    [
        -79.841435,
        42.178239
    ],
    [
        -79.842874,
        42.176683
    ],
    [
        -79.84349,
        42.176027
    ],
    [
        -79.84454,
        42.174906
    ],
    [
        -79.84599,
        42.173352
    ],
    [
        -79.846641,
        42.17266
    ],
    [
        -79.84697,
        42.172328
    ],
    [
        -79.847545,
        42.171799
    ],
    [
        -79.847986,
        42.171424
    ],
    [
        -79.848342,
        42.171133
    ],
    [
        -79.848644,
        42.170899
    ],
    [
        -79.849063,
        42.170599
    ],
    [
        -79.849602,
        42.170226
    ],
    [
        -79.850197,
        42.16985
    ],
    [
        -79.85066,
        42.169575
    ],
    [
        -79.851203,
        42.169267
    ],
    [
        -79.851911,
        42.1689
    ],
    [
        -79.852868,
        42.168449
    ],
    [
        -79.853554,
        42.168163
    ],
    [
        -79.85404,
        42.167973
    ],
    [
        -79.854742,
        42.167725
    ],
    [
        -79.855434,
        42.167498
    ],
    [
        -79.856378,
        42.167197
    ],
    [
        -79.858426,
        42.166562
    ],
    [
        -79.862704,
        42.165226
    ],
    [
        -79.86555,
        42.164335
    ],
    [
        -79.867396,
        42.163757
    ],
    [
        -79.869996,
        42.162939
    ],
    [
        -79.871888,
        42.162345
    ],
    [
        -79.873654,
        42.161796
    ],
    [
        -79.874472,
        42.161535
    ],
    [
        -79.875292,
        42.16126
    ],
    [
        -79.875937,
        42.161038
    ],
    [
        -79.87649,
        42.160837
    ],
    [
        -79.877203,
        42.160565
    ],
    [
        -79.877994,
        42.160249
    ],
    [
        -79.878679,
        42.15996
    ],
    [
        -79.879365,
        42.159662
    ],
    [
        -79.879965,
        42.159391
    ],
    [
        -79.880685,
        42.159067
    ],
    [
        -79.881503,
        42.158694
    ],
    [
        -79.882638,
        42.158175
    ],
    [
        -79.884132,
        42.157506
    ],
    [
        -79.88611,
        42.156601
    ],
    [
        -79.888004,
        42.155745
    ],
    [
        -79.889142,
        42.155229
    ],
    [
        -79.890026,
        42.154817
    ],
    [
        -79.890905,
        42.154421
    ],
    [
        -79.893246,
        42.15336
    ],
    [
        -79.894362,
        42.152853
    ],
    [
        -79.895054,
        42.152541
    ],
    [
        -79.89597,
        42.152119
    ],
    [
        -79.897285,
        42.151529
    ],
    [
        -79.898282,
        42.15106
    ],
    [
        -79.89948,
        42.150519
    ],
    [
        -79.900484,
        42.150057
    ],
    [
        -79.900861,
        42.149894
    ],
    [
        -79.90151,
        42.149597
    ],
    [
        -79.903252,
        42.148801
    ],
    [
        -79.903976,
        42.148488
    ],
    [
        -79.906163,
        42.147496
    ],
    [
        -79.910854,
        42.14536
    ],
    [
        -79.911322,
        42.145144
    ],
    [
        -79.912384,
        42.144647
    ],
    [
        -79.916663,
        42.1427
    ],
    [
        -79.923039,
        42.13981
    ],
    [
        -79.923671,
        42.139517
    ],
    [
        -79.924902,
        42.138952
    ],
    [
        -79.927881,
        42.137602
    ],
    [
        -79.932671,
        42.13542
    ],
    [
        -79.933837,
        42.134876
    ],
    [
        -79.935498,
        42.13404
    ],
    [
        -79.936292,
        42.133635
    ],
    [
        -79.938922,
        42.132298
    ],
    [
        -79.941018,
        42.13122
    ],
    [
        -79.942881,
        42.130283
    ],
    [
        -79.948252,
        42.127732
    ],
    [
        -79.950732,
        42.126474
    ],
    [
        -79.951404,
        42.126124
    ],
    [
        -79.952411,
        42.1256
    ],
    [
        -79.953305,
        42.125094
    ],
    [
        -79.95423,
        42.124559
    ],
    [
        -79.955666,
        42.123705
    ],
    [
        -79.957572,
        42.122573
    ],
    [
        -79.958382,
        42.122062
    ],
    [
        -79.95899,
        42.121669
    ],
    [
        -79.959305,
        42.121465
    ],
    [
        -79.960485,
        42.120689
    ],
    [
        -79.961203,
        42.120225
    ],
    [
        -79.962005,
        42.119692
    ],
    [
        -79.963135,
        42.118948
    ],
    [
        -79.964574,
        42.118007
    ],
    [
        -79.965786,
        42.117225
    ],
    [
        -79.966869,
        42.116537
    ],
    [
        -79.967101,
        42.116384
    ],
    [
        -79.967772,
        42.115941
    ],
    [
        -79.968195,
        42.115664
    ],
    [
        -79.96948,
        42.114823
    ],
    [
        -79.969688,
        42.114686
    ],
    [
        -79.970759,
        42.113984
    ],
    [
        -79.971512,
        42.113491
    ],
    [
        -79.972189,
        42.113048
    ],
    [
        -79.973068,
        42.112472
    ],
    [
        -79.973449,
        42.112223
    ],
    [
        -79.974394,
        42.111604
    ],
    [
        -79.974718,
        42.111391
    ],
    [
        -79.975739,
        42.110721
    ],
    [
        -79.975927,
        42.110598
    ],
    [
        -79.976863,
        42.109988
    ],
    [
        -79.977487,
        42.10958
    ],
    [
        -79.97766,
        42.109467
    ],
    [
        -79.978471,
        42.108938
    ],
    [
        -79.978733,
        42.108767
    ],
    [
        -79.979327,
        42.108376
    ],
    [
        -79.979738,
        42.108106
    ],
    [
        -79.979935,
        42.107977
    ],
    [
        -79.980094,
        42.107872
    ],
    [
        -79.980526,
        42.10759
    ],
    [
        -79.980825,
        42.107393
    ],
    [
        -79.981386,
        42.107025
    ],
    [
        -79.981768,
        42.106775
    ],
    [
        -79.981845,
        42.106725
    ],
    [
        -79.982392,
        42.106367
    ],
    [
        -79.982719,
        42.106153
    ],
    [
        -79.983384,
        42.105717
    ],
    [
        -79.983637,
        42.105551
    ],
    [
        -79.984424,
        42.105034
    ],
    [
        -79.98495,
        42.104689
    ],
    [
        -79.985057,
        42.104619
    ],
    [
        -79.985627,
        42.104245
    ],
    [
        -79.986178,
        42.103885
    ],
    [
        -79.986481,
        42.103687
    ],
    [
        -79.986828,
        42.103461
    ],
    [
        -79.987476,
        42.103038
    ],
    [
        -79.987647,
        42.102926
    ],
    [
        -79.987753,
        42.102857
    ],
    [
        -79.988191,
        42.10257
    ],
    [
        -79.988247,
        42.102533
    ],
    [
        -79.988456,
        42.102397
    ],
    [
        -79.988776,
        42.102188
    ],
    [
        -79.988804,
        42.10217
    ],
    [
        -79.988951,
        42.102077
    ],
    [
        -79.98927,
        42.101875
    ],
    [
        -79.989813,
        42.101547
    ],
    [
        -79.990414,
        42.10119
    ],
    [
        -79.990846,
        42.100946
    ],
    [
        -79.991335,
        42.10066
    ],
    [
        -79.991852,
        42.100384
    ],
    [
        -79.99231,
        42.100142
    ],
    [
        -79.993059,
        42.099766
    ],
    [
        -79.993621,
        42.099496
    ],
    [
        -79.993925,
        42.099364
    ],
    [
        -79.996491,
        42.098131
    ],
    [
        -79.999246,
        42.096807
    ],
    [
        -80.001504,
        42.095724
    ],
    [
        -80.002854,
        42.095074
    ],
    [
        -80.004303,
        42.094378
    ],
    [
        -80.0049,
        42.094091
    ],
    [
        -80.005789,
        42.093656
    ],
    [
        -80.006211,
        42.093436
    ],
    [
        -80.006692,
        42.093185
    ],
    [
        -80.007303,
        42.092848
    ],
    [
        -80.007866,
        42.092529
    ],
    [
        -80.008431,
        42.0922
    ],
    [
        -80.009023,
        42.091844
    ],
    [
        -80.009704,
        42.091415
    ],
    [
        -80.01012,
        42.091142
    ],
    [
        -80.010549,
        42.090855
    ],
    [
        -80.010897,
        42.090616
    ],
    [
        -80.011234,
        42.090377
    ],
    [
        -80.011603,
        42.090116
    ],
    [
        -80.012205,
        42.089672
    ],
    [
        -80.013297,
        42.088866
    ],
    [
        -80.015147,
        42.08751
    ],
    [
        -80.017655,
        42.085665
    ],
    [
        -80.019357,
        42.084414
    ],
    [
        -80.02061,
        42.083495
    ],
    [
        -80.022193,
        42.08233
    ],
    [
        -80.023027,
        42.081715
    ],
    [
        -80.024376,
        42.080724
    ],
    [
        -80.02523,
        42.080097
    ],
    [
        -80.025689,
        42.079759
    ],
    [
        -80.026764,
        42.078969
    ],
    [
        -80.027782,
        42.078231
    ],
    [
        -80.028087,
        42.078001
    ],
    [
        -80.029017,
        42.077303
    ],
    [
        -80.03034,
        42.076337
    ],
    [
        -80.031381,
        42.075577
    ],
    [
        -80.032127,
        42.075041
    ],
    [
        -80.032393,
        42.074853
    ],
    [
        -80.033261,
        42.074253
    ],
    [
        -80.034002,
        42.073757
    ],
    [
        -80.034747,
        42.073268
    ],
    [
        -80.035608,
        42.072715
    ],
    [
        -80.036473,
        42.072178
    ],
    [
        -80.036948,
        42.071887
    ],
    [
        -80.03785,
        42.071351
    ],
    [
        -80.038898,
        42.070741
    ],
    [
        -80.039966,
        42.070136
    ],
    [
        -80.040413,
        42.069895
    ],
    [
        -80.042972,
        42.068475
    ],
    [
        -80.045955,
        42.066823
    ],
    [
        -80.047784,
        42.065807
    ],
    [
        -80.048935,
        42.065164
    ],
    [
        -80.049085,
        42.065081
    ],
    [
        -80.052475,
        42.063202
    ],
    [
        -80.055479,
        42.06154
    ],
    [
        -80.056572,
        42.060931
    ],
    [
        -80.057423,
        42.060459
    ],
    [
        -80.058555,
        42.059832
    ],
    [
        -80.059481,
        42.059322
    ],
    [
        -80.06042,
        42.058797
    ],
    [
        -80.061131,
        42.058406
    ],
    [
        -80.062214,
        42.057803
    ],
    [
        -80.063137,
        42.057292
    ],
    [
        -80.06435,
        42.056618
    ],
    [
        -80.064957,
        42.05628
    ],
    [
        -80.065696,
        42.055871
    ],
    [
        -80.066133,
        42.055629
    ],
    [
        -80.066607,
        42.055379
    ],
    [
        -80.06699,
        42.055181
    ],
    [
        -80.067557,
        42.054915
    ],
    [
        -80.06807,
        42.054665
    ],
    [
        -80.06853,
        42.054446
    ],
    [
        -80.068872,
        42.054289
    ],
    [
        -80.069285,
        42.054102
    ],
    [
        -80.069846,
        42.05386
    ],
    [
        -80.07041,
        42.053619
    ],
    [
        -80.071013,
        42.053375
    ],
    [
        -80.071657,
        42.053123
    ],
    [
        -80.072399,
        42.052848
    ],
    [
        -80.073154,
        42.05259
    ],
    [
        -80.076102,
        42.051495
    ],
    [
        -80.077223,
        42.051074
    ],
    [
        -80.07839,
        42.05065
    ],
    [
        -80.079781,
        42.050146
    ],
    [
        -80.081141,
        42.049643
    ],
    [
        -80.081699,
        42.049444
    ],
    [
        -80.08352,
        42.048785
    ],
    [
        -80.086404,
        42.047737
    ],
    [
        -80.089695,
        42.046537
    ],
    [
        -80.091225,
        42.045981
    ],
    [
        -80.093634,
        42.045107
    ],
    [
        -80.095936,
        42.044267
    ],
    [
        -80.096635,
        42.044001
    ],
    [
        -80.097135,
        42.043804
    ],
    [
        -80.09787,
        42.043505
    ],
    [
        -80.098272,
        42.043333
    ],
    [
        -80.098647,
        42.043167
    ],
    [
        -80.09914,
        42.042948
    ],
    [
        -80.099742,
        42.042666
    ],
    [
        -80.100816,
        42.042164
    ],
    [
        -80.10242,
        42.041412
    ],
    [
        -80.103463,
        42.040919
    ],
    [
        -80.10477,
        42.040304
    ],
    [
        -80.105313,
        42.04005
    ],
    [
        -80.105994,
        42.03973
    ],
    [
        -80.106967,
        42.039271
    ],
    [
        -80.107947,
        42.038809
    ],
    [
        -80.108409,
        42.038592
    ],
    [
        -80.109456,
        42.0381
    ],
    [
        -80.111087,
        42.037331
    ],
    [
        -80.113338,
        42.036277
    ],
    [
        -80.115634,
        42.035195
    ],
    [
        -80.11838,
        42.033901
    ],
    [
        -80.12067,
        42.032823
    ],
    [
        -80.121949,
        42.032238
    ],
    [
        -80.122905,
        42.031791
    ],
    [
        -80.123693,
        42.031419
    ],
    [
        -80.12483,
        42.030865
    ],
    [
        -80.126188,
        42.030226
    ],
    [
        -80.127049,
        42.029818
    ],
    [
        -80.128792,
        42.029
    ],
    [
        -80.130689,
        42.028096
    ],
    [
        -80.131582,
        42.027673
    ],
    [
        -80.132292,
        42.027354
    ],
    [
        -80.133188,
        42.026965
    ],
    [
        -80.134015,
        42.026632
    ],
    [
        -80.13457,
        42.026415
    ],
    [
        -80.135319,
        42.026133
    ],
    [
        -80.136214,
        42.025818
    ],
    [
        -80.137326,
        42.025443
    ],
    [
        -80.138685,
        42.025032
    ],
    [
        -80.139568,
        42.024789
    ],
    [
        -80.141094,
        42.024398
    ],
    [
        -80.14233,
        42.024122
    ],
    [
        -80.143057,
        42.023971
    ],
    [
        -80.14381,
        42.02383
    ],
    [
        -80.144638,
        42.023687
    ],
    [
        -80.1455,
        42.023548
    ],
    [
        -80.146235,
        42.02344
    ],
    [
        -80.147064,
        42.023334
    ],
    [
        -80.147684,
        42.023262
    ],
    [
        -80.149435,
        42.023081
    ],
    [
        -80.170705,
        42.020945
    ],
    [
        -80.177207,
        42.020276
    ],
    [
        -80.178698,
        42.020101
    ],
    [
        -80.18327,
        42.019397
    ],
    [
        -80.188787,
        42.018527
    ],
    [
        -80.193543,
        42.0178
    ],
    [
        -80.210668,
        42.015144
    ],
    [
        -80.212041,
        42.014921
    ],
    [
        -80.213517,
        42.014639
    ],
    [
        -80.214769,
        42.014363
    ],
    [
        -80.216186,
        42.014036
    ],
    [
        -80.217859,
        42.013566
    ],
    [
        -80.219233,
        42.013136
    ],
    [
        -80.220574,
        42.012697
    ],
    [
        -80.222765,
        42.01185
    ],
    [
        -80.232727,
        42.00765
    ],
    [
        -80.236271,
        42.006188
    ],
    [
        -80.240657,
        42.004317
    ],
    [
        -80.242234,
        42.003655
    ],
    [
        -80.24615,
        42.002013
    ],
    [
        -80.249978,
        42.000388
    ],
    [
        -80.250362,
        42.000226
    ],
    [
        -80.25276,
        41.999186
    ],
    [
        -80.258353,
        41.996875
    ],
    [
        -80.269507,
        41.99215
    ],
    [
        -80.278766,
        41.988213
    ],
    [
        -80.279202,
        41.988027
    ],
    [
        -80.282754,
        41.986517
    ],
    [
        -80.288699,
        41.984016
    ],
    [
        -80.290707,
        41.983172
    ],
    [
        -80.292476,
        41.98244
    ],
    [
        -80.297748,
        41.980161
    ],
    [
        -80.304734,
        41.977337
    ],
    [
        -80.305335,
        41.977089
    ],
    [
        -80.308318,
        41.975822
    ],
    [
        -80.309854,
        41.975186
    ],
    [
        -80.316482,
        41.972519
    ],
    [
        -80.330516,
        41.966733
    ],
    [
        -80.332221,
        41.96603
    ],
    [
        -80.333903,
        41.965347
    ],
    [
        -80.334764,
        41.965012
    ],
    [
        -80.336749,
        41.964278
    ],
    [
        -80.337654,
        41.963949
    ],
    [
        -80.338581,
        41.9636
    ],
    [
        -80.339996,
        41.963081
    ],
    [
        -80.341213,
        41.962667
    ],
    [
        -80.343163,
        41.961956
    ],
    [
        -80.344013,
        41.961646
    ],
    [
        -80.351201,
        41.959013
    ],
    [
        -80.360337,
        41.955661
    ],
    [
        -80.362573,
        41.954825
    ],
    [
        -80.370717,
        41.951856
    ],
    [
        -80.376532,
        41.949742
    ],
    [
        -80.37709,
        41.949542
    ],
    [
        -80.37931,
        41.948736
    ],
    [
        -80.380008,
        41.948473
    ],
    [
        -80.382776,
        41.947444
    ],
    [
        -80.383505,
        41.947204
    ],
    [
        -80.384782,
        41.946829
    ],
    [
        -80.38564,
        41.946598
    ],
    [
        -80.387625,
        41.946231
    ],
    [
        -80.388366,
        41.946127
    ],
    [
        -80.38931,
        41.946023
    ],
    [
        -80.393204,
        41.945752
    ],
    [
        -80.39874,
        41.945406
    ],
    [
        -80.401992,
        41.945197
    ],
    [
        -80.402553,
        41.945159
    ],
    [
        -80.406229,
        41.944914
    ],
    [
        -80.412752,
        41.944483
    ],
    [
        -80.418052,
        41.944148
    ],
    [
        -80.426841,
        41.943562
    ],
    [
        -80.428352,
        41.943462
    ],
    [
        -80.429972,
        41.943358
    ],
    [
        -80.431506,
        41.943294
    ],
    [
        -80.432354,
        41.943278
    ],
    [
        -80.440379,
        41.943302
    ],
    [
        -80.451108,
        41.943318
    ],
    [
        -80.459615,
        41.94333
    ],
    [
        -80.466912,
        41.943353
    ],
    [
        -80.468542,
        41.943358
    ],
    [
        -80.482543,
        41.943353
    ],
    [
        -80.489601,
        41.943322
    ],
    [
        -80.50411,
        41.943384
    ],
    [
        -80.505054,
        41.943352
    ],
    [
        -80.506106,
        41.943248
    ],
    [
        -80.506964,
        41.943105
    ],
    [
        -80.508112,
        41.942818
    ],
    [
        -80.508949,
        41.94253
    ],
    [
        -80.509732,
        41.942203
    ],
    [
        -80.510669,
        41.941764
    ],
    [
        -80.518887,
        41.93765
    ],
    [
        -80.519531,
        41.937333
    ],
    [
        -80.521177,
        41.936519
    ],
    [
        -80.524,
        41.935174
    ],
    [
        -80.524592,
        41.934883
    ],
    [
        -80.535153,
        41.929649
    ],
    [
        -80.535513,
        41.929474
    ],
    [
        -80.541811,
        41.926396
    ],
    [
        -80.542877,
        41.925865
    ],
    [
        -80.543701,
        41.925454
    ],
    [
        -80.548272,
        41.923221
    ],
    [
        -80.549491,
        41.922678
    ],
    [
        -80.550512,
        41.922237
    ],
    [
        -80.551595,
        41.921838
    ],
    [
        -80.552667,
        41.921473
    ],
    [
        -80.554457,
        41.920857
    ],
    [
        -80.557086,
        41.920132
    ],
    [
        -80.559355,
        41.919532
    ],
    [
        -80.560896,
        41.919114
    ],
    [
        -80.563679,
        41.918387
    ],
    [
        -80.564834,
        41.918079
    ],
    [
        -80.566252,
        41.917709
    ],
    [
        -80.567801,
        41.917293
    ],
    [
        -80.571292,
        41.916382
    ],
    [
        -80.572532,
        41.916043
    ],
    [
        -80.573512,
        41.915797
    ],
    [
        -80.577151,
        41.914823
    ],
    [
        -80.581267,
        41.913738
    ],
    [
        -80.585536,
        41.912601
    ],
    [
        -80.589607,
        41.91153
    ],
    [
        -80.590822,
        41.911218
    ],
    [
        -80.593513,
        41.910495
    ],
    [
        -80.597001,
        41.909572
    ],
    [
        -80.597802,
        41.909345
    ],
    [
        -80.598771,
        41.909052
    ],
    [
        -80.599657,
        41.908768
    ],
    [
        -80.600537,
        41.908471
    ],
    [
        -80.601408,
        41.908161
    ],
    [
        -80.602271,
        41.907838
    ],
    [
        -80.617339,
        41.901846
    ],
    [
        -80.621021,
        41.900395
    ],
    [
        -80.62195,
        41.900018
    ],
    [
        -80.622722,
        41.899685
    ],
    [
        -80.623087,
        41.899538
    ],
    [
        -80.624717,
        41.898885
    ],
    [
        -80.627821,
        41.89764
    ],
    [
        -80.630822,
        41.89647
    ],
    [
        -80.632598,
        41.895747
    ],
    [
        -80.63601,
        41.894397
    ],
    [
        -80.636905,
        41.894034
    ],
    [
        -80.638348,
        41.893475
    ],
    [
        -80.639825,
        41.892859
    ],
    [
        -80.641141,
        41.892343
    ],
    [
        -80.648613,
        41.889365
    ],
    [
        -80.650035,
        41.888809
    ],
    [
        -80.651591,
        41.888176
    ],
    [
        -80.65281,
        41.887692
    ],
    [
        -80.654448,
        41.887021
    ],
    [
        -80.655785,
        41.886451
    ],
    [
        -80.657277,
        41.885775
    ],
    [
        -80.659187,
        41.884872
    ],
    [
        -80.65953,
        41.884706
    ],
    [
        -80.660963,
        41.883975
    ],
    [
        -80.662382,
        41.883202
    ],
    [
        -80.662845,
        41.88295
    ],
    [
        -80.664917,
        41.88177
    ],
    [
        -80.665525,
        41.881416
    ],
    [
        -80.666149,
        41.88102
    ],
    [
        -80.667189,
        41.880383
    ],
    [
        -80.668048,
        41.87981
    ],
    [
        -80.668736,
        41.879368
    ],
    [
        -80.669673,
        41.878742
    ],
    [
        -80.670358,
        41.878263
    ],
    [
        -80.67182,
        41.87719
    ],
    [
        -80.672827,
        41.876421
    ],
    [
        -80.673514,
        41.87588
    ],
    [
        -80.67379,
        41.875663
    ],
    [
        -80.674845,
        41.874795
    ],
    [
        -80.675691,
        41.874068
    ],
    [
        -80.676499,
        41.87335
    ],
    [
        -80.678477,
        41.871508
    ],
    [
        -80.679781,
        41.870269
    ],
    [
        -80.683697,
        41.866579
    ],
    [
        -80.688006,
        41.862491
    ],
    [
        -80.690078,
        41.86055
    ],
    [
        -80.691467,
        41.859222
    ],
    [
        -80.694904,
        41.855976
    ],
    [
        -80.695589,
        41.855353
    ],
    [
        -80.696362,
        41.854664
    ],
    [
        -80.69741,
        41.853823
    ],
    [
        -80.697916,
        41.853426
    ],
    [
        -80.698681,
        41.852865
    ],
    [
        -80.699395,
        41.852366
    ],
    [
        -80.699739,
        41.852125
    ],
    [
        -80.701203,
        41.851173
    ],
    [
        -80.702283,
        41.850526
    ],
    [
        -80.703567,
        41.849805
    ],
    [
        -80.704764,
        41.84918
    ],
    [
        -80.705982,
        41.848585
    ],
    [
        -80.706924,
        41.848151
    ],
    [
        -80.708269,
        41.847582
    ],
    [
        -80.709658,
        41.847029
    ],
    [
        -80.711185,
        41.846476
    ],
    [
        -80.712282,
        41.846114
    ],
    [
        -80.713532,
        41.845688
    ],
    [
        -80.717761,
        41.844271
    ],
    [
        -80.718426,
        41.844035
    ],
    [
        -80.718843,
        41.843888
    ],
    [
        -80.720691,
        41.843171
    ],
    [
        -80.721727,
        41.842729
    ],
    [
        -80.722759,
        41.842264
    ],
    [
        -80.723663,
        41.841836
    ],
    [
        -80.72452,
        41.841412
    ],
    [
        -80.725794,
        41.840742
    ],
    [
        -80.726351,
        41.840435
    ],
    [
        -80.727469,
        41.839756
    ],
    [
        -80.728089,
        41.839382
    ],
    [
        -80.729152,
        41.838697
    ],
    [
        -80.732115,
        41.836651
    ],
    [
        -80.733715,
        41.835632
    ],
    [
        -80.735061,
        41.834845
    ],
    [
        -80.736919,
        41.833849
    ],
    [
        -80.738956,
        41.832888
    ],
    [
        -80.74022,
        41.832346
    ],
    [
        -80.741451,
        41.831847
    ],
    [
        -80.742257,
        41.83155
    ],
    [
        -80.743516,
        41.831102
    ],
    [
        -80.744541,
        41.830774
    ],
    [
        -80.74572,
        41.83041
    ],
    [
        -80.746823,
        41.830106
    ],
    [
        -80.74762,
        41.829899
    ],
    [
        -80.74794,
        41.829817
    ],
    [
        -80.748761,
        41.829625
    ],
    [
        -80.749812,
        41.829388
    ],
    [
        -80.751258,
        41.829107
    ],
    [
        -80.752913,
        41.828823
    ],
    [
        -80.754351,
        41.828621
    ],
    [
        -80.755898,
        41.828441
    ],
    [
        -80.756598,
        41.828385
    ],
    [
        -80.757355,
        41.82831
    ],
    [
        -80.758304,
        41.828246
    ],
    [
        -80.759776,
        41.82817
    ],
    [
        -80.761113,
        41.828138
    ],
    [
        -80.763804,
        41.828135
    ],
    [
        -80.76493,
        41.828132
    ],
    [
        -80.766036,
        41.828137
    ],
    [
        -80.775878,
        41.828157
    ],
    [
        -80.78186,
        41.828151
    ],
    [
        -80.783667,
        41.828159
    ],
    [
        -80.784284,
        41.828145
    ],
    [
        -80.784906,
        41.828113
    ],
    [
        -80.785626,
        41.828053
    ],
    [
        -80.786271,
        41.827981
    ],
    [
        -80.786903,
        41.827892
    ],
    [
        -80.787453,
        41.8278
    ],
    [
        -80.787877,
        41.827717
    ],
    [
        -80.78823,
        41.827637
    ],
    [
        -80.789164,
        41.827409
    ],
    [
        -80.789567,
        41.827295
    ],
    [
        -80.789913,
        41.827184
    ],
    [
        -80.790254,
        41.827064
    ],
    [
        -80.790995,
        41.826785
    ],
    [
        -80.791404,
        41.826618
    ],
    [
        -80.791757,
        41.826469
    ],
    [
        -80.792639,
        41.826047
    ],
    [
        -80.79356,
        41.825532
    ],
    [
        -80.794,
        41.825263
    ],
    [
        -80.794379,
        41.825012
    ],
    [
        -80.800684,
        41.820412
    ],
    [
        -80.810047,
        41.813549
    ],
    [
        -80.811543,
        41.812467
    ],
    [
        -80.812403,
        41.811861
    ],
    [
        -80.814187,
        41.810681
    ],
    [
        -80.81517,
        41.810068
    ],
    [
        -80.816027,
        41.80955
    ],
    [
        -80.819875,
        41.807348
    ],
    [
        -80.820971,
        41.80671
    ],
    [
        -80.828257,
        41.802542
    ],
    [
        -80.830631,
        41.801167
    ],
    [
        -80.832807,
        41.799928
    ],
    [
        -80.834219,
        41.799134
    ],
    [
        -80.834633,
        41.798897
    ],
    [
        -80.835925,
        41.798129
    ],
    [
        -80.836852,
        41.797607
    ],
    [
        -80.838826,
        41.796476
    ],
    [
        -80.839902,
        41.795859
    ],
    [
        -80.840714,
        41.795381
    ],
    [
        -80.845344,
        41.792722
    ],
    [
        -80.847569,
        41.791438
    ],
    [
        -80.85056,
        41.789726
    ],
    [
        -80.851647,
        41.789093
    ],
    [
        -80.85271,
        41.78849
    ],
    [
        -80.853174,
        41.788209
    ],
    [
        -80.854493,
        41.787464
    ],
    [
        -80.859791,
        41.784429
    ],
    [
        -80.859985,
        41.784318
    ],
    [
        -80.860846,
        41.783784
    ],
    [
        -80.861774,
        41.783278
    ],
    [
        -80.862976,
        41.782581
    ],
    [
        -80.864688,
        41.78161
    ],
    [
        -80.866511,
        41.780556
    ],
    [
        -80.870504,
        41.778277
    ],
    [
        -80.871632,
        41.77767
    ],
    [
        -80.8727,
        41.777134
    ],
    [
        -80.873911,
        41.776562
    ],
    [
        -80.875194,
        41.776002
    ],
    [
        -80.876793,
        41.775358
    ],
    [
        -80.877649,
        41.775035
    ],
    [
        -80.878918,
        41.774596
    ],
    [
        -80.879635,
        41.774358
    ],
    [
        -80.881308,
        41.77386
    ],
    [
        -80.882299,
        41.773586
    ],
    [
        -80.883162,
        41.773365
    ],
    [
        -80.883983,
        41.77317
    ],
    [
        -80.885103,
        41.772924
    ],
    [
        -80.886528,
        41.772645
    ],
    [
        -80.888113,
        41.772377
    ],
    [
        -80.889506,
        41.772179
    ],
    [
        -80.890907,
        41.772018
    ],
    [
        -80.891895,
        41.771919
    ],
    [
        -80.892853,
        41.77184
    ],
    [
        -80.894363,
        41.771749
    ],
    [
        -80.897913,
        41.771592
    ],
    [
        -80.908183,
        41.771107
    ],
    [
        -80.909687,
        41.771043
    ],
    [
        -80.914781,
        41.770801
    ],
    [
        -80.932074,
        41.769994
    ],
    [
        -80.93525,
        41.769875
    ],
    [
        -80.940156,
        41.769734
    ],
    [
        -80.940782,
        41.769734
    ],
    [
        -80.94242,
        41.769673
    ],
    [
        -80.944194,
        41.769606
    ],
    [
        -80.947634,
        41.769499
    ],
    [
        -80.950303,
        41.769427
    ],
    [
        -80.951854,
        41.76937
    ],
    [
        -80.954857,
        41.769283
    ],
    [
        -80.95781,
        41.769194
    ],
    [
        -80.959024,
        41.769165
    ],
    [
        -80.961362,
        41.769094
    ],
    [
        -80.962553,
        41.769048
    ],
    [
        -80.965944,
        41.768953
    ],
    [
        -80.96896,
        41.768855
    ],
    [
        -80.970542,
        41.768821
    ],
    [
        -80.973585,
        41.768716
    ],
    [
        -80.975897,
        41.76865
    ],
    [
        -80.977881,
        41.768581
    ],
    [
        -80.979081,
        41.768517
    ],
    [
        -80.980734,
        41.768388
    ],
    [
        -80.981476,
        41.768323
    ],
    [
        -80.982765,
        41.76817
    ],
    [
        -80.983752,
        41.768039
    ],
    [
        -80.984716,
        41.767893
    ],
    [
        -80.985612,
        41.767744
    ],
    [
        -80.986649,
        41.767551
    ],
    [
        -80.988561,
        41.767149
    ],
    [
        -80.989571,
        41.76691
    ],
    [
        -80.990711,
        41.766609
    ],
    [
        -80.99292,
        41.765993
    ],
    [
        -80.995299,
        41.765348
    ],
    [
        -80.996426,
        41.76506
    ],
    [
        -80.99743,
        41.764816
    ],
    [
        -80.998397,
        41.764613
    ],
    [
        -80.999581,
        41.764375
    ],
    [
        -81.00047,
        41.764217
    ],
    [
        -81.001601,
        41.764032
    ],
    [
        -81.002809,
        41.763864
    ],
    [
        -81.003421,
        41.763801
    ],
    [
        -81.004455,
        41.763696
    ],
    [
        -81.006102,
        41.763536
    ],
    [
        -81.021104,
        41.762791
    ],
    [
        -81.025696,
        41.762574
    ],
    [
        -81.029902,
        41.762394
    ],
    [
        -81.031747,
        41.762305
    ],
    [
        -81.033661,
        41.762177
    ],
    [
        -81.034502,
        41.762088
    ],
    [
        -81.035052,
        41.762029
    ],
    [
        -81.037078,
        41.761792
    ],
    [
        -81.038657,
        41.761517
    ],
    [
        -81.040365,
        41.761182
    ],
    [
        -81.042004,
        41.760804
    ],
    [
        -81.044331,
        41.760168
    ],
    [
        -81.046244,
        41.759614
    ],
    [
        -81.04809,
        41.75898
    ],
    [
        -81.049901,
        41.758284
    ],
    [
        -81.051909,
        41.757481
    ],
    [
        -81.052236,
        41.757345
    ],
    [
        -81.056834,
        41.755519
    ],
    [
        -81.071255,
        41.7498
    ],
    [
        -81.073142,
        41.749047
    ],
    [
        -81.08158,
        41.745678
    ],
    [
        -81.085229,
        41.744254
    ],
    [
        -81.09364,
        41.740866
    ],
    [
        -81.09576,
        41.740034
    ],
    [
        -81.097219,
        41.739393
    ],
    [
        -81.098764,
        41.738689
    ],
    [
        -81.100606,
        41.737677
    ],
    [
        -81.102824,
        41.73616
    ],
    [
        -81.105476,
        41.734408
    ],
    [
        -81.112729,
        41.729414
    ],
    [
        -81.114576,
        41.72809
    ],
    [
        -81.114737,
        41.727973
    ],
    [
        -81.116591,
        41.726801
    ],
    [
        -81.117999,
        41.726019
    ],
    [
        -81.119681,
        41.725123
    ],
    [
        -81.121483,
        41.724277
    ],
    [
        -81.123183,
        41.723547
    ],
    [
        -81.124985,
        41.722861
    ],
    [
        -81.126917,
        41.722201
    ],
    [
        -81.128659,
        41.721676
    ],
    [
        -81.130461,
        41.721196
    ],
    [
        -81.132212,
        41.720779
    ],
    [
        -81.134495,
        41.720331
    ],
    [
        -81.137738,
        41.7199
    ],
    [
        -81.143158,
        41.719179
    ],
    [
        -81.143767,
        41.719108
    ],
    [
        -81.148203,
        41.718505
    ],
    [
        -81.154357,
        41.717672
    ],
    [
        -81.156863,
        41.717256
    ],
    [
        -81.158047,
        41.716999
    ],
    [
        -81.159266,
        41.716711
    ],
    [
        -81.160768,
        41.716307
    ],
    [
        -81.16227,
        41.715833
    ],
    [
        -81.164118,
        41.715185
    ],
    [
        -81.164656,
        41.714956
    ],
    [
        -81.165661,
        41.714538
    ],
    [
        -81.166018,
        41.714375
    ],
    [
        -81.166905,
        41.713962
    ],
    [
        -81.168004,
        41.713418
    ],
    [
        -81.169394,
        41.712649
    ],
    [
        -81.170852,
        41.711776
    ],
    [
        -81.172918,
        41.710539
    ],
    [
        -81.179196,
        41.706754
    ],
    [
        -81.182798,
        41.70456
    ],
    [
        -81.184228,
        41.703679
    ],
    [
        -81.188835,
        41.700903
    ],
    [
        -81.200695,
        41.693709
    ],
    [
        -81.202164,
        41.692828
    ],
    [
        -81.20371,
        41.691896
    ],
    [
        -81.206007,
        41.690506
    ],
    [
        -81.212171,
        41.68676
    ],
    [
        -81.212756,
        41.686406
    ],
    [
        -81.217858,
        41.683316
    ],
    [
        -81.218748,
        41.682778
    ],
    [
        -81.220378,
        41.681791
    ],
    [
        -81.221777,
        41.681022
    ],
    [
        -81.222892,
        41.680458
    ],
    [
        -81.224418,
        41.679707
    ],
    [
        -81.22563,
        41.679143
    ],
    [
        -81.237065,
        41.674545
    ],
    [
        -81.23725,
        41.674471
    ],
    [
        -81.242023,
        41.67256
    ],
    [
        -81.242102,
        41.672529
    ],
    [
        -81.24326,
        41.672041
    ],
    [
        -81.243491,
        41.671949
    ],
    [
        -81.243997,
        41.671748
    ],
    [
        -81.248633,
        41.6699
    ],
    [
        -81.252152,
        41.668464
    ],
    [
        -81.253783,
        41.667765
    ],
    [
        -81.256126,
        41.6667
    ],
    [
        -81.260231,
        41.664819
    ],
    [
        -81.260548,
        41.664676
    ],
    [
        -81.263045,
        41.663551
    ],
    [
        -81.26348,
        41.663355
    ],
    [
        -81.269395,
        41.660691
    ],
    [
        -81.271093,
        41.659936
    ],
    [
        -81.273636,
        41.658806
    ],
    [
        -81.279301,
        41.656261
    ],
    [
        -81.282948,
        41.654613
    ],
    [
        -81.286988,
        41.652826
    ],
    [
        -81.288789,
        41.652049
    ],
    [
        -81.291445,
        41.651023
    ],
    [
        -81.293488,
        41.650361
    ],
    [
        -81.295327,
        41.649831
    ],
    [
        -81.297876,
        41.649219
    ],
    [
        -81.300032,
        41.6488
    ],
    [
        -81.301204,
        41.648595
    ],
    [
        -81.301889,
        41.6485
    ],
    [
        -81.303982,
        41.648228
    ],
    [
        -81.305091,
        41.648126
    ],
    [
        -81.306378,
        41.64802
    ],
    [
        -81.307187,
        41.647979
    ],
    [
        -81.309408,
        41.647903
    ],
    [
        -81.311694,
        41.647926
    ],
    [
        -81.316947,
        41.64808
    ],
    [
        -81.321084,
        41.648202
    ],
    [
        -81.321559,
        41.648216
    ],
    [
        -81.323679,
        41.648237
    ],
    [
        -81.324606,
        41.64823
    ],
    [
        -81.324842,
        41.648227
    ],
    [
        -81.325171,
        41.648222
    ],
    [
        -81.326192,
        41.648184
    ],
    [
        -81.32748,
        41.648121
    ],
    [
        -81.327912,
        41.648089
    ],
    [
        -81.328806,
        41.648015
    ],
    [
        -81.329459,
        41.647953
    ],
    [
        -81.330297,
        41.647863
    ],
    [
        -81.331082,
        41.647769
    ],
    [
        -81.333155,
        41.647491
    ],
    [
        -81.333457,
        41.64745
    ],
    [
        -81.333828,
        41.6474
    ],
    [
        -81.334417,
        41.647326
    ],
    [
        -81.336552,
        41.647035
    ],
    [
        -81.339965,
        41.646576
    ],
    [
        -81.34412,
        41.645972
    ],
    [
        -81.344643,
        41.645896
    ],
    [
        -81.347642,
        41.645492
    ],
    [
        -81.347911,
        41.645457
    ],
    [
        -81.35168,
        41.644949
    ],
    [
        -81.35547,
        41.64444
    ],
    [
        -81.356943,
        41.644241
    ],
    [
        -81.35847,
        41.64403
    ],
    [
        -81.359219,
        41.643924
    ],
    [
        -81.36052,
        41.643741
    ],
    [
        -81.362979,
        41.643406
    ],
    [
        -81.363063,
        41.643395
    ],
    [
        -81.364135,
        41.643247
    ],
    [
        -81.365148,
        41.643095
    ],
    [
        -81.366076,
        41.642934
    ],
    [
        -81.366769,
        41.642796
    ],
    [
        -81.367629,
        41.642612
    ],
    [
        -81.367911,
        41.642556
    ],
    [
        -81.368847,
        41.642369
    ],
    [
        -81.370185,
        41.642016
    ],
    [
        -81.371525,
        41.641663
    ],
    [
        -81.372813,
        41.641265
    ],
    [
        -81.373755,
        41.640953
    ],
    [
        -81.374032,
        41.640861
    ],
    [
        -81.375302,
        41.640412
    ],
    [
        -81.376607,
        41.639918
    ],
    [
        -81.377628,
        41.639527
    ],
    [
        -81.379104,
        41.638873
    ],
    [
        -81.380033,
        41.638429
    ],
    [
        -81.380952,
        41.637991
    ],
    [
        -81.381765,
        41.637603
    ],
    [
        -81.3838,
        41.636628
    ],
    [
        -81.385411,
        41.635857
    ],
    [
        -81.386495,
        41.635369
    ],
    [
        -81.387215,
        41.635005
    ],
    [
        -81.387391,
        41.634916
    ],
    [
        -81.388477,
        41.634363
    ],
    [
        -81.38985,
        41.633606
    ],
    [
        -81.390379,
        41.63329
    ],
    [
        -81.391533,
        41.632599
    ],
    [
        -81.391903,
        41.632369
    ],
    [
        -81.39282,
        41.631797
    ],
    [
        -81.393352,
        41.631456
    ],
    [
        -81.393471,
        41.631375
    ],
    [
        -81.394271,
        41.630868
    ],
    [
        -81.396179,
        41.62961
    ],
    [
        -81.397852,
        41.628527
    ],
    [
        -81.39863,
        41.628032
    ],
    [
        -81.398669,
        41.628007
    ],
    [
        -81.39946,
        41.627502
    ],
    [
        -81.399838,
        41.62724
    ],
    [
        -81.400237,
        41.626977
    ],
    [
        -81.401911,
        41.625898
    ],
    [
        -81.40271,
        41.625383
    ],
    [
        -81.403811,
        41.624658
    ],
    [
        -81.404823,
        41.624007
    ],
    [
        -81.406164,
        41.623144
    ],
    [
        -81.407774,
        41.622103
    ],
    [
        -81.408779,
        41.621454
    ],
    [
        -81.409743,
        41.620831
    ],
    [
        -81.410356,
        41.620439
    ],
    [
        -81.411301,
        41.619809
    ],
    [
        -81.41364,
        41.618297
    ],
    [
        -81.413872,
        41.618139
    ],
    [
        -81.415107,
        41.617352
    ],
    [
        -81.416635,
        41.616393
    ],
    [
        -81.41816,
        41.615463
    ],
    [
        -81.419864,
        41.614417
    ],
    [
        -81.421093,
        41.613646
    ],
    [
        -81.422596,
        41.61273
    ],
    [
        -81.423547,
        41.612151
    ],
    [
        -81.425666,
        41.610839
    ],
    [
        -81.426097,
        41.610572
    ],
    [
        -81.428592,
        41.60897
    ],
    [
        -81.428768,
        41.608857
    ],
    [
        -81.433148,
        41.606248
    ],
    [
        -81.433497,
        41.60604
    ],
    [
        -81.435306,
        41.604944
    ],
    [
        -81.437169,
        41.603763
    ],
    [
        -81.438851,
        41.602717
    ],
    [
        -81.439623,
        41.602184
    ],
    [
        -81.440568,
        41.601497
    ],
    [
        -81.442798,
        41.599594
    ],
    [
        -81.443031,
        41.599379
    ],
    [
        -81.444095,
        41.598141
    ],
    [
        -81.444911,
        41.597146
    ],
    [
        -81.445812,
        41.595894
    ],
    [
        -81.446353,
        41.595169
    ],
    [
        -81.446971,
        41.594321
    ],
    [
        -81.447417,
        41.593756
    ],
    [
        -81.448086,
        41.593179
    ],
    [
        -81.448423,
        41.592942
    ],
    [
        -81.448781,
        41.592717
    ],
    [
        -81.449207,
        41.592493
    ],
    [
        -81.449651,
        41.592288
    ],
    [
        -81.450103,
        41.592113
    ],
    [
        -81.450707,
        41.591912
    ],
    [
        -81.451713,
        41.591629
    ],
    [
        -81.452803,
        41.591372
    ],
    [
        -81.45406,
        41.591075
    ],
    [
        -81.454664,
        41.590988
    ],
    [
        -81.454999,
        41.590948
    ],
    [
        -81.45522,
        41.590922
    ],
    [
        -81.456617,
        41.59079
    ],
    [
        -81.458198,
        41.590716
    ],
    [
        -81.458895,
        41.590686
    ],
    [
        -81.459004,
        41.590682
    ],
    [
        -81.460263,
        41.590629
    ],
    [
        -81.462065,
        41.590554
    ],
    [
        -81.462322,
        41.590544
    ],
    [
        -81.462785,
        41.590524
    ],
    [
        -81.46375,
        41.590489
    ],
    [
        -81.466747,
        41.590345
    ],
    [
        -81.468592,
        41.590282
    ],
    [
        -81.470188,
        41.590213
    ],
    [
        -81.470494,
        41.590201
    ],
    [
        -81.4729,
        41.590104
    ],
    [
        -81.473719,
        41.590052
    ],
    [
        -81.474345,
        41.590012
    ],
    [
        -81.474671,
        41.589997
    ],
    [
        -81.476744,
        41.589913
    ],
    [
        -81.477675,
        41.589871
    ],
    [
        -81.478272,
        41.589853
    ],
    [
        -81.47921,
        41.589838
    ],
    [
        -81.479996,
        41.589864
    ],
    [
        -81.480583,
        41.589919
    ],
    [
        -81.481161,
        41.590005
    ],
    [
        -81.482115,
        41.590218
    ],
    [
        -81.483106,
        41.590548
    ],
    [
        -81.483799,
        41.590854
    ],
    [
        -81.484264,
        41.591109
    ],
    [
        -81.484625,
        41.591359
    ],
    [
        -81.485208,
        41.591764
    ],
    [
        -81.485612,
        41.59211
    ],
    [
        -81.486041,
        41.592528
    ],
    [
        -81.486376,
        41.592894
    ],
    [
        -81.486745,
        41.59342
    ],
    [
        -81.487002,
        41.593856
    ],
    [
        -81.487148,
        41.594203
    ],
    [
        -81.487328,
        41.594633
    ],
    [
        -81.487534,
        41.595339
    ],
    [
        -81.487728,
        41.596163
    ],
    [
        -81.487826,
        41.596675
    ],
    [
        -81.487834,
        41.596713
    ],
    [
        -81.487916,
        41.597143
    ],
    [
        -81.488096,
        41.597971
    ],
    [
        -81.488255,
        41.598536
    ],
    [
        -81.488526,
        41.599313
    ],
    [
        -81.488697,
        41.599768
    ],
    [
        -81.488989,
        41.600282
    ],
    [
        -81.489262,
        41.60074
    ],
    [
        -81.489418,
        41.600975
    ],
    [
        -81.489762,
        41.601424
    ],
    [
        -81.490123,
        41.60182
    ],
    [
        -81.490442,
        41.602152
    ],
    [
        -81.490706,
        41.602435
    ],
    [
        -81.491238,
        41.602961
    ],
    [
        -81.491608,
        41.603349
    ],
    [
        -81.491943,
        41.60367
    ],
    [
        -81.492568,
        41.604303
    ],
    [
        -81.492886,
        41.604636
    ],
    [
        -81.493221,
        41.604932
    ],
    [
        -81.493513,
        41.605175
    ],
    [
        -81.493873,
        41.605394
    ],
    [
        -81.494225,
        41.605548
    ],
    [
        -81.494757,
        41.605689
    ],
    [
        -81.495002,
        41.605719
    ],
    [
        -81.495221,
        41.605747
    ],
    [
        -81.495718,
        41.605753
    ],
    [
        -81.496233,
        41.605682
    ],
    [
        -81.496602,
        41.60558
    ],
    [
        -81.496971,
        41.605426
    ],
    [
        -81.497418,
        41.605195
    ],
    [
        -81.498259,
        41.604572
    ],
    [
        -81.498911,
        41.604104
    ],
    [
        -81.501113,
        41.602617
    ],
    [
        -81.5014,
        41.602435
    ],
    [
        -81.501723,
        41.602235
    ],
    [
        -81.502561,
        41.601743
    ],
    [
        -81.503334,
        41.601314
    ],
    [
        -81.505043,
        41.600342
    ],
    [
        -81.506305,
        41.599543
    ],
    [
        -81.507404,
        41.598882
    ],
    [
        -81.508297,
        41.598336
    ],
    [
        -81.509241,
        41.597726
    ],
    [
        -81.50991,
        41.597322
    ],
    [
        -81.510554,
        41.596892
    ],
    [
        -81.511006,
        41.596652
    ],
    [
        -81.511615,
        41.596286
    ],
    [
        -81.512056,
        41.595993
    ],
    [
        -81.513411,
        41.595204
    ],
    [
        -81.51402,
        41.594853
    ],
    [
        -81.514305,
        41.594678
    ],
    [
        -81.515506,
        41.593984
    ],
    [
        -81.51647,
        41.593448
    ],
    [
        -81.517732,
        41.592659
    ],
    [
        -81.518814,
        41.591998
    ],
    [
        -81.519577,
        41.591561
    ],
    [
        -81.520453,
        41.591028
    ],
    [
        -81.521354,
        41.590476
    ],
    [
        -81.522367,
        41.589879
    ],
    [
        -81.523732,
        41.589109
    ],
    [
        -81.524367,
        41.588737
    ],
    [
        -81.525463,
        41.588162
    ],
    [
        -81.526365,
        41.587685
    ],
    [
        -81.527085,
        41.587288
    ],
    [
        -81.527463,
        41.587084
    ],
    [
        -81.528038,
        41.58675
    ],
    [
        -81.528965,
        41.586288
    ],
    [
        -81.529668,
        41.585966
    ],
    [
        -81.530424,
        41.585646
    ],
    [
        -81.531282,
        41.585286
    ],
    [
        -81.532037,
        41.584984
    ],
    [
        -81.532655,
        41.584708
    ],
    [
        -81.533359,
        41.584426
    ],
    [
        -81.534072,
        41.584169
    ],
    [
        -81.534758,
        41.583893
    ],
    [
        -81.535531,
        41.583591
    ],
    [
        -81.536303,
        41.583283
    ],
    [
        -81.537033,
        41.582975
    ],
    [
        -81.537805,
        41.58259
    ],
    [
        -81.538732,
        41.582127
    ],
    [
        -81.539668,
        41.581654
    ],
    [
        -81.540196,
        41.581369
    ],
    [
        -81.541301,
        41.58075
    ],
    [
        -81.542228,
        41.580262
    ],
    [
        -81.543104,
        41.579839
    ],
    [
        -81.543739,
        41.579562
    ],
    [
        -81.544451,
        41.579299
    ],
    [
        -81.545232,
        41.579068
    ],
    [
        -81.545606,
        41.578975
    ],
    [
        -81.54652,
        41.578747
    ],
    [
        -81.547521,
        41.578468
    ],
    [
        -81.548577,
        41.578179
    ],
    [
        -81.549448,
        41.577935
    ],
    [
        -81.550183,
        41.577685
    ],
    [
        -81.550482,
        41.577569
    ],
    [
        -81.55124,
        41.577245
    ],
    [
        -81.551858,
        41.576962
    ],
    [
        -81.552562,
        41.576584
    ],
    [
        -81.553215,
        41.576263
    ],
    [
        -81.553975,
        41.575892
    ],
    [
        -81.554652,
        41.57553
    ],
    [
        -81.555352,
        41.575171
    ],
    [
        -81.555913,
        41.574875
    ],
    [
        -81.55658,
        41.574534
    ],
    [
        -81.557599,
        41.574008
    ],
    [
        -81.558212,
        41.573716
    ],
    [
        -81.558909,
        41.573423
    ],
    [
        -81.559618,
        41.573152
    ],
    [
        -81.56028,
        41.572937
    ],
    [
        -81.561294,
        41.572633
    ],
    [
        -81.562457,
        41.572294
    ],
    [
        -81.56387,
        41.57187
    ],
    [
        -81.564816,
        41.571597
    ],
    [
        -81.565949,
        41.571276
    ],
    [
        -81.567078,
        41.570893
    ],
    [
        -81.567899,
        41.570606
    ],
    [
        -81.568162,
        41.570513
    ],
    [
        -81.568885,
        41.570259
    ],
    [
        -81.569623,
        41.569979
    ],
    [
        -81.570807,
        41.569516
    ],
    [
        -81.571772,
        41.56914
    ],
    [
        -81.572958,
        41.568703
    ],
    [
        -81.57394,
        41.568355
    ],
    [
        -81.575098,
        41.567964
    ],
    [
        -81.575916,
        41.567706
    ],
    [
        -81.576189,
        41.567629
    ],
    [
        -81.57739,
        41.567295
    ],
    [
        -81.578748,
        41.566932
    ],
    [
        -81.581424,
        41.566191
    ],
    [
        -81.58342,
        41.565639
    ],
    [
        -81.584703,
        41.565289
    ],
    [
        -81.585545,
        41.565061
    ],
    [
        -81.586315,
        41.564849
    ],
    [
        -81.586705,
        41.564731
    ],
    [
        -81.587096,
        41.56461
    ],
    [
        -81.58755,
        41.564458
    ],
    [
        -81.588093,
        41.564243
    ],
    [
        -81.58858,
        41.564028
    ],
    [
        -81.588969,
        41.563832
    ],
    [
        -81.589402,
        41.56361
    ],
    [
        -81.5899,
        41.563301
    ],
    [
        -81.59088,
        41.562645
    ],
    [
        -81.591277,
        41.562327
    ],
    [
        -81.591723,
        41.561927
    ],
    [
        -81.59215,
        41.561498
    ],
    [
        -81.592328,
        41.561295
    ],
    [
        -81.592524,
        41.561071
    ],
    [
        -81.592754,
        41.560797
    ],
    [
        -81.593015,
        41.56042
    ],
    [
        -81.593278,
        41.560004
    ],
    [
        -81.593455,
        41.559694
    ],
    [
        -81.593678,
        41.559262
    ],
    [
        -81.593894,
        41.558758
    ],
    [
        -81.594117,
        41.558213
    ],
    [
        -81.594267,
        41.557866
    ],
    [
        -81.594465,
        41.557461
    ],
    [
        -81.594746,
        41.556969
    ],
    [
        -81.595164,
        41.556356
    ],
    [
        -81.595463,
        41.555977
    ],
    [
        -81.595806,
        41.5556
    ],
    [
        -81.596112,
        41.555281
    ],
    [
        -81.596595,
        41.554843
    ],
    [
        -81.597279,
        41.554305
    ],
    [
        -81.597887,
        41.553904
    ],
    [
        -81.598719,
        41.55341
    ],
    [
        -81.599757,
        41.55292
    ],
    [
        -81.601151,
        41.552253
    ],
    [
        -81.602786,
        41.551481
    ],
    [
        -81.603792,
        41.550995
    ],
    [
        -81.60504,
        41.550407
    ],
    [
        -81.607221,
        41.549366
    ],
    [
        -81.608084,
        41.548941
    ],
    [
        -81.608821,
        41.548593
    ],
    [
        -81.610025,
        41.548037
    ],
    [
        -81.612171,
        41.547081
    ],
    [
        -81.615325,
        41.545672
    ],
    [
        -81.615743,
        41.545488
    ],
    [
        -81.616136,
        41.545309
    ],
    [
        -81.617928,
        41.544494
    ],
    [
        -81.618803,
        41.544078
    ],
    [
        -81.6207,
        41.543145
    ],
    [
        -81.622828,
        41.542085
    ],
    [
        -81.624067,
        41.541471
    ],
    [
        -81.624267,
        41.541373
    ],
    [
        -81.625305,
        41.540854
    ],
    [
        -81.625572,
        41.540716
    ],
    [
        -81.625713,
        41.540643
    ],
    [
        -81.626451,
        41.540273
    ],
    [
        -81.62735,
        41.539896
    ],
    [
        -81.627708,
        41.539771
    ],
    [
        -81.628039,
        41.539666
    ],
    [
        -81.62841,
        41.539569
    ],
    [
        -81.628862,
        41.539462
    ],
    [
        -81.629242,
        41.539393
    ],
    [
        -81.629597,
        41.53934
    ],
    [
        -81.630098,
        41.539282
    ],
    [
        -81.630621,
        41.539249
    ],
    [
        -81.631294,
        41.539245
    ],
    [
        -81.631758,
        41.539242
    ],
    [
        -81.632604,
        41.539241
    ],
    [
        -81.632766,
        41.539241
    ],
    [
        -81.633534,
        41.539224
    ],
    [
        -81.634512,
        41.53922
    ],
    [
        -81.635462,
        41.539203
    ],
    [
        -81.635913,
        41.539173
    ],
    [
        -81.636215,
        41.53914
    ],
    [
        -81.63684,
        41.539059
    ],
    [
        -81.63754,
        41.538931
    ],
    [
        -81.638042,
        41.53882
    ],
    [
        -81.638515,
        41.538685
    ],
    [
        -81.639087,
        41.538517
    ],
    [
        -81.639763,
        41.538276
    ],
    [
        -81.640057,
        41.538142
    ],
    [
        -81.640476,
        41.537954
    ],
    [
        -81.64106,
        41.537648
    ],
    [
        -81.641747,
        41.53728
    ],
    [
        -81.642355,
        41.536921
    ],
    [
        -81.642641,
        41.536755
    ],
    [
        -81.643437,
        41.536292
    ],
    [
        -81.643767,
        41.536108
    ],
    [
        -81.644059,
        41.535946
    ],
    [
        -81.64515,
        41.535311
    ],
    [
        -81.645857,
        41.534831
    ],
    [
        -81.646081,
        41.534678
    ],
    [
        -81.646328,
        41.534484
    ],
    [
        -81.64645,
        41.534389
    ],
    [
        -81.646752,
        41.534124
    ],
    [
        -81.647823,
        41.53312
    ],
    [
        -81.648284,
        41.532713
    ],
    [
        -81.648984,
        41.532097
    ],
    [
        -81.649348,
        41.531824
    ],
    [
        -81.649584,
        41.531644
    ],
    [
        -81.6499,
        41.531429
    ],
    [
        -81.650945,
        41.530799
    ],
    [
        -81.651229,
        41.530624
    ],
    [
        -81.652126,
        41.530152
    ],
    [
        -81.654078,
        41.529147
    ],
    [
        -81.655748,
        41.528271
    ],
    [
        -81.657898,
        41.527153
    ],
    [
        -81.659682,
        41.526218
    ],
    [
        -81.660769,
        41.525655
    ],
    [
        -81.666459,
        41.522693
    ],
    [
        -81.667103,
        41.522352
    ],
    [
        -81.668583,
        41.521579
    ],
    [
        -81.670134,
        41.520778
    ],
    [
        -81.672055,
        41.519779
    ],
    [
        -81.672951,
        41.519303
    ],
    [
        -81.673398,
        41.519072
    ],
    [
        -81.673959,
        41.518781
    ],
    [
        -81.674114,
        41.518705
    ],
    [
        -81.674736,
        41.518412
    ],
    [
        -81.674978,
        41.518292
    ],
    [
        -81.675617,
        41.517968
    ],
    [
        -81.676006,
        41.517775
    ],
    [
        -81.676217,
        41.51767
    ],
    [
        -81.676817,
        41.517361
    ],
    [
        -81.677258,
        41.517139
    ],
    [
        -81.678068,
        41.516685
    ],
    [
        -81.679004,
        41.516199
    ],
    [
        -81.679917,
        41.515745
    ],
    [
        -81.681231,
        41.514995
    ],
    [
        -81.682063,
        41.514554
    ],
    [
        -81.682684,
        41.514176
    ],
    [
        -81.682978,
        41.513983
    ],
    [
        -81.683242,
        41.513796
    ],
    [
        -81.683464,
        41.513622
    ],
    [
        -81.684343,
        41.512915
    ],
    [
        -81.685081,
        41.512317
    ],
    [
        -81.685522,
        41.512005
    ],
    [
        -81.685939,
        41.511732
    ],
    [
        -81.686387,
        41.511478
    ],
    [
        -81.687186,
        41.511061
    ],
    [
        -81.688986,
        41.510127
    ],
    [
        -81.689614,
        41.50982
    ],
    [
        -81.689866,
        41.509697
    ],
    [
        -81.6911,
        41.509095
    ],
    [
        -81.69172,
        41.508815
    ],
    [
        -81.69205,
        41.508666
    ],
    [
        -81.692555,
        41.508425
    ],
    [
        -81.693508,
        41.507972
    ],
    [
        -81.693635,
        41.507911
    ],
    [
        -81.693537,
        41.507791
    ],
    [
        -81.693361,
        41.507602
    ],
    [
        -81.693257,
        41.507488
    ],
    [
        -81.693189,
        41.507413
    ],
    [
        -81.692985,
        41.507195
    ],
    [
        -81.692916,
        41.50712
    ],
    [
        -81.692887,
        41.507088
    ],
    [
        -81.692431,
        41.506565
    ],
    [
        -81.69232,
        41.506442
    ],
    [
        -81.691616,
        41.505657
    ],
    [
        -81.6915,
        41.505527
    ],
    [
        -81.691417,
        41.505435
    ],
    [
        -81.690795,
        41.504741
    ],
    [
        -81.690161,
        41.504043
    ],
    [
        -81.690074,
        41.503936
    ],
    [
        -81.689958,
        41.503808
    ],
    [
        -81.689596,
        41.503416
    ],
    [
        -81.689395,
        41.503188
    ],
    [
        -81.689328,
        41.503125
    ],
    [
        -81.68922,
        41.503008
    ],
    [
        -81.689159,
        41.502932
    ],
    [
        -81.688683,
        41.502414
    ],
    [
        -81.688543,
        41.502266
    ],
    [
        -81.688733,
        41.502231
    ],
    [
        -81.688818,
        41.502199
    ],
    [
        -81.690246,
        41.501471
    ],
    [
        -81.690317,
        41.501377
    ],
    [
        -81.690433,
        41.501318
    ],
    [
        -81.690842,
        41.501109
    ],
    [
        -81.691076,
        41.50099
    ],
    [
        -81.691561,
        41.500742
    ],
    [
        -81.691924,
        41.500557
    ],
    [
        -81.692026,
        41.500505
    ],
    [
        -81.692742,
        41.500139
    ],
    [
        -81.692886,
        41.500066
    ],
    [
        -81.693008,
        41.5002
    ],
    [
        -81.693364,
        41.50059
    ],
    [
        -81.693404,
        41.500633
    ],
    [
        -81.693486,
        41.500724
    ],
    [
        -81.693594,
        41.50067
    ],
    [
        -81.694115,
        41.500413
    ],
    [
        -81.694283,
        41.500329
    ],
    [
        -81.694451,
        41.500247
    ],
    [
        -81.694916,
        41.500016
    ],
    [
        -81.694944,
        41.500001
    ],
    [
        -81.694994,
        41.499975
    ],
    [
        -81.695014,
        41.499955
    ],
    [
        -81.695025,
        41.499933
    ],
    [
        -81.695031,
        41.499904
    ],
    [
        -81.695025,
        41.499874
    ],
    [
        -81.695008,
        41.499848
    ],
    [
        -81.69499,
        41.499828
    ],
    [
        -81.694597,
        41.499381
    ],
    [
        -81.694488,
        41.499257
    ]
]

