

export const Loader = ({isLoading}) => { 


    if(isLoading){
        return (
            <div class="loader-container">
                <div class="loader"></div>
            </div>
        )
    } 
}