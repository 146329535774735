import { useState } from "react"
import { auth } from "../firebase-config"
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendEmailVerification  } from "firebase/auth"
import { Tooltip } from 'react-tooltip'

export const Auth = ({renderCondition}) => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

  

    const validAccount = () => {
        return email.split('@')[1]=='beemac.com'
    }

    const signUp = async () => {

        if(validAccount()){
            console.log('is valid account ', validAccount())
            try {
                await createUserWithEmailAndPassword(auth, email, password).then(
                     (userCredential) => {
                        const user = userCredential.user

                        try {

                            sendEmailVerification(user).then(
                                () => {
                                    window.location.reload()
                                }
                            )

                        } catch(err) {
                            console.log(err)
                        }
                    }
                )
            } catch(err){
                console.log(err)
            }
        } else {
            alert('Must sign up with a Beemac Email 🐝')
        }
        
    }

    const signIn = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch(err){
            console.log(err)
        }
    }

    const logOut = async () => {
        try {
            await signOut(auth)
        } catch(err){
            console.log(err)
        }
    }

    if(!renderCondition){
        return (
            <div className="card">
                <h3 className="auth-header-text">Bee-Bot Login</h3>
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                        <input className="form-control auth-input" placeholder="Email..." onChange={(e) => setEmail(e.target.value)}/>
                        <input type="password" className="form-control auth-input" placeholder="Password..." onChange={(e) => setPassword(e.target.value)}/>       
                        <button className="btn btn-secondary" onClick={signIn}>Sign In</button>
                        <div className="spacer"></div>
                        <button className="btn btn-secondary" onClick={signUp}>Sign Up</button>
                        
                    </div>
                    <div className="col-sm-2">
                        <a data-tooltip-id="my-tooltip" data-tooltip-place="top" className="auth-tooltip-entry">❔</a>
                        <Tooltip id="my-tooltip" className="auth-tooltip">
                            <div>
                                <h3>How to Sign Up</h3>
                                <ol >
                                    <li>Use your Beemac Email and a secure password and click sign up</li>
                                    <li>Briefly wait as a verification email is sent to your Outlook, within 5 minutes</li>
                                    <li>Click the verification link in the email and come back to this page</li>
                                    <li>Start pricing lanes with Beebot!</li>
                                </ol>
                            </div>
                        </Tooltip>

                    </div>
                    
                </div>              
            </div>
        )
    } else {
        return(
            <button className="auth-button btn btn-secondary" onClick={logOut}>Log Out</button>
        )
    }
    
}